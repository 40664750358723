import React, {useEffect, useState, useRef} from 'react';


import {
  XYPlot,
  XAxis,
  YAxis,
  VerticalGridLines,
  HorizontalGridLines,
  VerticalBarSeries,
  VerticalBarSeriesCanvas,
  LabelSeries,
  DiscreteColorLegend
} from 'react-vis';



/*
const greenData = [ 'JAN', y: 10} {x: 'FEB', y: 5}, {x: 'MAR', y: 15},{x: 'APR', y: 10},{x: 'MAY', y: 12},{x: 'JUN', y: 6},{x: 'JUL', y: 2},{x: 'AUG', y: 12},{x: 'SEP', y: 6},{x: 'OCT', y: 2}];

const blueData = [{x: 'A', y: 12}, {x: 'B', y: 2}, {x: 'C', y: 11},{x: 'D', y: 5},{x: 'E', y: 6},{x: 'F', y: 12},{x: 'G', y: 4},{x: 'H', y: 6},{x: 'I', y: 12},{x: 'J', y: 4}];

const labelData = greenData.map((d, idx) => ({
  x: d.x,
  y: Math.max(greenData[idx].y, blueData[idx].y)
}));
*/


const ITEMS = [
  'Instances',
  'Popups',
  'Clicks'
];

const country_names = [{x:'JAN', y: 0}, {x:'FEB', y:0},{x:'MAR', y:0}, {x:'APR',y:0},{x:'MAY',y:0}, {x:'JUN',y:0},{x:'JUL',y:0},{x:'AUG',y:0},{x:'SEP',y:0},{x:'OCT',y:0},{x:'NOV',y:0},{x:'DEC',y:0}]

function BarChart(props) {

  const { classes } = props;

  const [useCanvas, setUseCanvas] = React.useState(false)

  const content = useCanvas ? 'TOGGLE TO SVG' : 'TOGGLE TO CANVAS';

  const BarSeries = useCanvas ? VerticalBarSeriesCanvas : VerticalBarSeries;

  const [chartData, setChartData] = React.useState({'blueData':[], 'greenData':[], 'darkblueData':[]})
  //const [greenData, setgreenData] = React.useState([])
  //const [darkblueData, darkblueData] = React.useState([])
  //var labelData = []

  
  useEffect(()=>{
    
    const temp_array = [... props.yearly_analytics]

    const tempblueData  = [...country_names]

    const tempgreenData = [...country_names]

    const tempdarkblueData = [...country_names]

    temp_array.forEach((x)=>{

      if(x.month){
        tempblueData[x.month - 1] = { x: tempblueData[x.month - 1].x , y: x.total_instances}
        tempgreenData[x.month - 1] = { x: tempblueData[x.month - 1].x , y: x.total_popups}
        tempdarkblueData[x.month - 1] = { x: tempblueData[x.month - 1].x , y: x.total_clicks}
      }

    });

    console.log('array was',{'blueData':tempblueData, 'greenData':tempgreenData , 'darkblueData':tempdarkblueData})
    setChartData({'blueData':tempblueData, 'greenData':tempgreenData , 'darkblueData':tempdarkblueData})
    /*
    labelData = props.yearly_analytics.map((d, idx) => ({
      x: d.month,
      y: Math.max(d.total_instances, d.total_popups, d.total_clicks)
    }));
    */
  },[props.yearly_analytics])

  return (
    <div style = {{position: 'relative'}}>
      <div style = {{position: 'absolute','right': '2%','marginTop':'8px',zIndex: '100'}}>
       <DiscreteColorLegend
          orientation="horizontal"
          items={ITEMS}
        />
        </div>

      <div style = {{position: 'absolute','marginTop':'8px',zIndex: '110'}}>
      <XYPlot xType="ordinal" width={580} height={240} xDistance={100}>
        <VerticalGridLines />
        <HorizontalGridLines />
        <XAxis />
        <YAxis />
        <BarSeries className="vertical-bar-series-example" data={chartData.blueData} />
        <BarSeries data={chartData.greenData} /> 
        <BarSeries data={chartData.darkblueData} /> 
        {/*<LabelSeries data={labelData} getLabel={d => d.x} />*/}
      </XYPlot>
      </div>
    </div>
  );

}


export default (BarChart);
