import React, {useEffect, useState, useRef} from 'react';

import {Paper, Grid,  FormControl, Input, InputLabel, FormHelperText, Select, MenuItem,  makeStyles, useTheme, Chip,  Button} from '@material-ui/core';

import { withStyles, recomposeColor } from '@material-ui/core/styles';


import PropTypes from 'prop-types';

import Rating from '@material-ui/lab/Rating';
import StarBorderIcon from '@material-ui/icons/StarBorder';
import FavoriteIcon from '@material-ui/icons/Favorite';
import SentimentVeryDissatisfiedIcon from '@material-ui/icons/SentimentVeryDissatisfied';
import SentimentDissatisfiedIcon from '@material-ui/icons/SentimentDissatisfied';
import SentimentSatisfiedIcon from '@material-ui/icons/SentimentSatisfied';
import SentimentSatisfiedAltIcon from '@material-ui/icons/SentimentSatisfiedAltOutlined';
import SentimentVerySatisfiedIcon from '@material-ui/icons/SentimentVerySatisfied';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import TextField from '@material-ui/core/TextField';

import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormLabel from '@material-ui/core/FormLabel';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import Checkbox from '@material-ui/core/Checkbox';


const styles = (theme) => ({
  paper: {
    maxWidth: 936,
    width: '500px',
    height: '500px',
    margin: 'auto',
    overflow: 'auto',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: 'D43636',
  },
  searchBar: {
    borderBottom: '1px solid rgba(0, 0, 0, 0.12)',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  block: {
    display: 'block',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  contentWrapper: {
    margin: '16px',
  },
  pBar:{
    marginTop: '100px',
    marginBottom: '100px',
    marginLeft: '48%',
    position:'relative',
  },
  backPBar:{
    marginTop: '100px',
    marginBottom: '100px',
    marginLeft: '10%',
    position:'relative',
    color: 'white',
  },
});



const StyledRating = withStyles({
  iconFilled: {
    color: '#ff6d75',
  },
  iconHover: {
    color: '#ff3d47',
  },
})(Rating);

const customIcons = {
  1: {
    icon: <SentimentVeryDissatisfiedIcon />,
    label: 'Very Dissatisfied',
  },
  2: {
    icon: <SentimentDissatisfiedIcon />,
    label: 'Dissatisfied',
  },
  3: {
    icon: <SentimentSatisfiedIcon />,
    label: 'Neutral',
  },
  4: {
    icon: <SentimentSatisfiedAltIcon />,
    label: 'Satisfied',
  },
  5: {
    icon: <SentimentVerySatisfiedIcon />,
    label: 'Very Satisfied',
  },
};

const labels = {
  1: 'Poor',
  10: 'Excellent',
};

 
function IconContainer(props) {
  const { value, ...other } = props;
  return <span {...other}>{customIcons[value].icon}</span>;
}

function Feedback(props) {






  const { classes } = props;



  

  const setbnNeeds = (value) =>{
    const new_obj = JSON.parse(JSON.stringify(props.feedbackState))
    new_obj.bn_needs = value
    props.setFeedbackState(new_obj)
  }


  const set_easy_to_use = (value) =>{
    const new_obj = JSON.parse(JSON.stringify(props.feedbackState))
    new_obj.easy_to_use = value
    props.setFeedbackState(new_obj)
  }

  
  const set_stable = (value) =>{
    const new_obj = JSON.parse(JSON.stringify(props.feedbackState))
    new_obj.stable = value
    props.setFeedbackState(new_obj)
  }


  const handleBrowserChange = (event) => {

    const new_obj = JSON.parse(JSON.stringify(props.feedbackState))
    new_obj.browserState = event.target.value
    props.setFeedbackState(new_obj)

  };



  const handleDetailsChange = (event) => {

    const new_obj = JSON.parse(JSON.stringify(props.feedbackState))

    new_obj.detailsInput = event.target.value

    props.setFeedbackState(new_obj)

  };



  const handleIssuesChange = (event,to_check) => {

    const new_obj = JSON.parse(JSON.stringify(props.feedbackState))
    new_obj.issuesCheck[to_check] = event.target.checked
    props.setFeedbackState(new_obj)

  }


  return (

    <Paper className={classes.paper}>

      <div className={classes.contentWrapper}>

      <Typography style={{marginTop:'24px'}}  variant="h6" component="h6">

       <b>Lets make Geosaur better!</b> 

      </Typography> 

      
      <Box style={{'width':'100%','padding':'0px', marginTop:'12px'}} component="fieldset"  borderColor="transparent">

        <Typography component="legend">How well did Geosaur fit your businesss needs?</Typography>

        <Rating value = {props.feedbackState.bn_needs} style = {{'marginTop':'4px'}} name="customized-9"  max={10} size="large" onChange={(event, newValue) => { setbnNeeds(newValue); }} />

      </Box> 

      <Box style={{'width':'100%','padding':'0px', marginTop:'10px'}} component="fieldset"borderColor="transparent">

        <Typography component="legend">Which browser do you normally use, while using Geosaur?</Typography>

        <RadioGroup style={{"marginTop":"4px",marginLeft:'2px'}} row aria-label="browser" name="browser_type" value={props.feedbackState.browserState} onChange={handleBrowserChange} >

          <FormControlLabel value="Chrome" control={ <Radio color='primary' size="small" /> } label="Chrome" />

          <FormControlLabel value="Safari" control={ <Radio color='primary' size="small" /> } label="Safari" />

          <FormControlLabel value="Firefox" control={ <Radio color='primary' size="small" /> } label="Firefox" />

          <FormControlLabel value="Internet Explorer" control={ <Radio color='primary' size="small" /> } label="Internet Explorer" />

         </RadioGroup>

      </Box>
       

      <Box style={{'width':'100%','padding':'0px',marginTop:'8px'}} component="fieldset" borderColor="transparent">

        <Typography component="legend">How easy is the App to use?</Typography>

        <Rating value = {props.feedbackState.easy_to_use} style = {{'marginTop':'8px'}} name="customized1-10"  max={10} size="large" onChange={(event, newValue) => { set_easy_to_use(newValue) }} />

      </Box>

      <Box style={{'width':'100%','padding':'0px',marginTop:'8px'}} component="fieldset" borderColor="transparent">

        <Typography component="legend">Is the App stable and crash free?</Typography>

        <Rating value = {props.feedbackState.stable} style = {{'marginTop':'8px'}} name="customized-11"  max={10} size="large" onChange={(event, newValue) => { set_stable(newValue); }}/>

      </Box>

      <Box style={{'width':'100%','padding':'0px',marginTop:'8px'}} component="fieldset"  borderColor="transparent">

      <Typography component="legend">Did you experience the following issues while using the App?</Typography>

      <FormControl component="fieldset" className={classes.formControl}>

        <div style = {{marginLeft:'8px',marginTop:'12px'}}>

        <FormLabel component="legend">Check all that apply</FormLabel>

        <FormGroup style={{marginTop:'8px'}}>

          <FormControlLabel
            control={<Checkbox checked={props.feedbackState.issuesCheck.difficult} onChange={event => handleIssuesChange(event, "difficult")} name="difficult" />}
            label="Too difficult to use."
          />

          <FormControlLabel
            control={<Checkbox checked={props.feedbackState.issuesCheck.crash} onChange={event => handleIssuesChange(event, "crash")} name="crash" />}
            label="App crashed"
          />

          <FormControlLabel
            control={<Checkbox checked={props.feedbackState.issuesCheck.deploy} onChange={event => handleIssuesChange(event, "deploy")} name="deploy" />}
            label="Couldn't deploy sections to store"
          />

            <FormControlLabel
            control={<Checkbox checked={props.feedbackState.issuesCheck.dataloss} onChange={event => handleIssuesChange(event, "dataloss")} name="dataloss" />}
            label="Data Loss"
           />     

          <FormControlLabel
            control={<Checkbox checked={props.feedbackState.issuesCheck.customization} onChange={event => handleIssuesChange(event, "customization")} name="customization" />}
            label="Sections should be more customizable"
           />

          <FormControlLabel
            control={<Checkbox checked={props.feedbackState.issuesCheck.didnt} onChange={event => handleIssuesChange(event, "didnt")} name="didnt" />}
            label="Didn't experience any issues"
          />

        </FormGroup>

        </div>


      </FormControl>

      </Box>

      <Box style={{'width':'100%','padding':'0px',marginTop:'8px'}} component="fieldset"  borderColor="transparent">
    
      <Typography component="legend">Let us know how we can improve Geosaur</Typography>

      <TextField
          id="standard-textarea"
          label="Additional Details"
          value={props.feedbackState.detailsInput}
          size="small"
          placeholder="Tell us more about the issue"
          variant = "outlined"
          onChange= {handleDetailsChange}
          style = {{marginLeft: '1%', width : '98%', marginTop: '12px'}}
          multiline 
        />

      <Button style = {{marginLeft: '1%', width : '98%', marginTop: '12px'}} variant="outlined" color="primary" onClick={event => props.handleFeedbackSubmit(props.feedbackState)}>Submit</Button>

      </Box>

      </div>

    </Paper>

  );

}

Feedback.propTypes = {
  classes: PropTypes.object.isRequired,
};


export default withStyles(styles)(Feedback);
