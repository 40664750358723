import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { createMuiTheme, ThemeProvider, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Hidden from '@material-ui/core/Hidden';
import Typography from '@material-ui/core/Typography';
import Link from '@material-ui/core/Link';
import Navigator from './Navigator';
import Content from './Content';
import Header from './Header';
import CustomSnackbar from '../Snackbar/Snackbar'
import { Route, Switch } from 'react-router-dom';
import PeopleIcon from '@material-ui/icons/People';
import DnsRoundedIcon from '@material-ui/icons/DnsRounded';
import PermMediaOutlinedIcon from '@material-ui/icons/PhotoSizeSelectActual';
 
import PublicIcon from '@material-ui/icons/Public';
import SettingsEthernetIcon from '@material-ui/icons/SettingsEthernet';
import SettingsInputComponentIcon from '@material-ui/icons/SettingsInputComponent';
import TimerIcon from '@material-ui/icons/Timer';
import SettingsIcon from '@material-ui/icons/Settings';
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup';
import AssessmentIcon from '@material-ui/icons/Assessment';
import StoreIcon from '@material-ui/icons/Store';
import Manage from '../Manage/Manange'
import Stores from '../Stores/Stores'
import Settings from '../Settings/Settings'
import ThemeContent from '../ThemeContent/ThemeContent'
import ShippingContent from '../ShippingContent/ShippingContent'
import { withRouter } from 'react-router-dom'
import Feedback from '../Feedback/Feedback'
import Popover from '@material-ui/core/Popover';
import Popper from '@material-ui/core/Popover';

import Analytics from '../AnalyticsNew/Analytics'
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress'





const preventDefault = (event) => {event.preventDefault();window.open("https://www.datasaur.co.uk", '_blank')}


function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" onClick = {preventDefault} href="https://www.datasaur.co.uk">
        Datasaur
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

let theme = createMuiTheme({
  palette: {
    primary: {
      light: '#63ccff',
      main: '#009be5',
      dark: '#006db3',
    },
  },
  typography: {
    h5: {
      fontWeight: 500,
      fontSize: 26,
      letterSpacing: 0.5,
    },
  },
  shape: {
    borderRadius: 8,
  },
  props: {
    MuiTab: {
      disableRipple: true,
    },
  },
  mixins: {
    toolbar: {
      minHeight: 48,
    },
  },
});

theme = {
  ...theme,
  overrides: {
    MuiDrawer: {
      paper: {
        backgroundColor: '#18202c',
      },
    },
    MuiButton: {
      label: {
        textTransform: 'none',
      },
      contained: {
        boxShadow: 'none',
        '&:active': {
          boxShadow: 'none',
        },
      },
    },
    MuiTabs: {
      root: {
        marginLeft: theme.spacing(1),
      },
      indicator: {
        height: 3,
        borderTopLeftRadius: 3,
        borderTopRightRadius: 3,
        backgroundColor: theme.palette.common.white,
      },
    },
    MuiTab: {
      root: {
        textTransform: 'none',
        margin: '0 16px',
        minWidth: 0,
        padding: 0,
        [theme.breakpoints.up('md')]: {
          padding: 0,
          minWidth: 0,
        },
      },
    },
    MuiIconButton: {
      root: {
        padding: theme.spacing(1),
      },
    },
    MuiTooltip: {
      tooltip: {
        borderRadius: 4,
      },
    },
    MuiDivider: {
      root: {
        backgroundColor: '#404854',
      },
    },
    MuiListItemText: {
      primary: {
        fontWeight: theme.typography.fontWeightMedium,
      },
    },
    MuiListItemIcon: {
      root: {
        color: 'inherit',
        marginRight: 0,
        '& svg': {
          fontSize: 20,
        },
      },
    },
    MuiAvatar: {
      root: {
        width: 32,
        height: 32,
      },
    },
  },
};

const drawerWidth = 256;

const styles = {
  root: {
    display: 'flex',
    minHeight: '100vh',
  },
  backPBar:{
    marginTop: '100px',
    marginBottom: '100px',
    marginLeft: '10%',
    position:'relative',
    color: 'white',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: 'D43636',
  },
  drawer: {
    [theme.breakpoints.up('sm')]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  app: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
  },
  main: {
    flex: 1,
    padding: theme.spacing(6, 4),
    background: '#eaeff1',
  },
  mainAnalytics: {
    flex: 1,
    padding: theme.spacing(2, 2),
    background: '#eaeff1',
  },
  footer: {
    padding: theme.spacing(2),
    background: '#eaeff1',
  },
};


function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = React.useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });

  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    
    // Add event listener
    window.addEventListener("resize", handleResize);
    
    // Call handler right away so state gets updated with initial window size
    handleResize();
    
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount

  return windowSize;
}

function Paperbase(props) {

  // start here

  const { classes } = props;

  // State handling

  const isMountedRef = useRef(null);

  const [mobileOpen, setMobileOpen] = React.useState(false);

  const [shop_data, set_shop_data] = React.useState({});

  const [shop_error, set_shop_error] = React.useState(false);

  const [add_header_value, set_add_header_value] = React.useState(0);

  const [header_title, set_header_title] = React.useState('New Section')

  const [active_comp, set_active_com] = React.useState('')

  const [show_snackbar, set_showsnackbar] = React.useState({'severity':'success','snack_text':'','is_open':false})

  const [feedbackState, setFeedbackState] = React.useState({bn_needs: 0,browserState:'',easy_to_use:0,stable:0,issuesCheck:{'difficult': false,'crash':false,'deploy':false,'dataloss':false,'customization':false,'didnt':false},'detailsInput':"\n\n\n\n\n\n\n"});



  //const [window_dimensions, set_window_dimensions] = React.useState([])

  const size = useWindowSize();

  const [is_poper_open ,set_is_poper_open] = React.useState(false)

  const [categories, setCategories] = React.useState([
    {
      id: 'Sections',
      children: [
        { id: 'Add', icon: <PeopleIcon />, active: true },
        { id: 'Manage', icon: <DnsRoundedIcon /> },
        { id: 'Stores', icon: <StoreIcon /> },
        { id: 'Themes', icon: <PermMediaOutlinedIcon /> },
        { id: 'Shipping', icon: <PublicIcon /> },
      ],
    },
    {
      id: 'Report',
      children: [
        { id: 'Analytics', icon: <AssessmentIcon /> },
        { id: 'Feedback', icon: <TimerIcon /> },
       /* { id: 'Documentation', icon: <PhonelinkSetupIcon /> },*/
      ],
    }
  ]);


  const handle_snackbar = (severity, text, is_open) => {
    set_showsnackbar({'severity':severity,'snack_text':text,'is_open':is_open})
  }


  const handleAddHeaderChange = (event, newValue) => {
    console.log(newValue)
    set_add_header_value(newValue);
  };


  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleClose = () =>{
    set_is_poper_open(false)
    console.log('handle close')
  }


  const handleFeedbackSubmit = (feedback_obj) =>{
    if(props.current_shop){
      save_feedback_to_db(feedback_obj,props.current_shop)
    }
    console.log('fb obj was',feedback_obj)
  }

  useEffect(() => {

    if(props.current_shop){
      get_shop_data(props.current_shop)
     }

   }, [props.current_shop])

/*
  useEffect(() => {

    var w = window.innerWidth;

    var h = window.innerHeight;

    set_window_dimensions([w,h])
 
   }, [])

*/

  useEffect(() =>{

    isMountedRef.current = true

    var temp_array = [...categories]

    var current_id = ''

    /*
    var temp_string = props.location.pathname.slice(1, props.location.pathname.length)

    if(temp_string === ''){
      temp_string = 'add'
    }
    */

    switch (props.location.pathname) {    

      case '/add':
        set_header_title('New Section')
        break
  
      case '/manage':
        set_header_title('Manage')
  
        break
  
      case '/stores':
        set_header_title('Stores')
  
        break
  
      case '/shipping':
        set_header_title('Shipping')
     
        break

  
      case '/themes':
        set_header_title('Themes')
        break
      case '/analytics':
        set_header_title('Analytics')
        break
      case '/feedback':
       // set_header_title('Feedback')
        //set_is_poper_open(true)
        break
        /*
      case '/documentation':
        set_header_title('Documentation')
        break
        */
      default:
        set_header_title('New Section')
        break
  
    }

    return () => isMountedRef.current = false;

  },[props.location.pathname])

  const nav_item_clicked  = (index,id,chilId) => {


    var temp_array = [...categories]

    temp_array[0]['children'] = temp_array[0]['children'].map(obj => {return {'id':obj.id,'icon':obj.icon}})
    temp_array[1]['children'] = temp_array[1]['children'].map(obj => {return {'id':obj.id,'icon':obj.icon}})

    temp_array.forEach((section)=>{
      section.children.forEach((child)=>{
        if(child.id === chilId){
          child['active'] = true
        }
      });
    });

    setCategories(temp_array)
    
    if(chilId == 'Feedback'){
      
      set_is_poper_open(true)

    }else{
    props.history.replace('/'+chilId.toLowerCase())
    }
  };



  return (

    <ThemeProvider theme={theme}>

       <div>

      <div className={classes.root}>
        <CssBaseline />
        <nav className={classes.drawer}>
          <Hidden smUp implementation="js">
            <Navigator 
              categories = {categories}
              shop_name = {shop_data}
              list_item_clicked = {nav_item_clicked}
              PaperProps={{ style: { width: drawerWidth } }}
              variant="temporary"
              open={mobileOpen}
              onClose={handleDrawerToggle}
            />
          </Hidden>
          <Hidden xsDown implementation="css">
            <Navigator categories = {categories} shop_name = {shop_data} list_item_clicked = {nav_item_clicked} PaperProps={{ style: { width: drawerWidth } }} />
          </Hidden>
        </nav>
        <div className={classes.app}>
          <Header title = {header_title} add_header_value =  {add_header_value} set_add_header_value = {handleAddHeaderChange.bind(this)} onDrawerToggle={handleDrawerToggle} />

          
         <Switch>
           <Route path="/stores">
           <main className={classes.main}>
            <Stores current_shop = {props.current_shop} handle_snack = {handle_snackbar}/>
            </main> 
           </Route>
           <Route path="/manage">
           <main className={classes.main}>
            <Manage current_shop = {props.current_shop} handle_snack = {handle_snackbar} />
            </main> 
           </Route>
           <Route path="/shipping">
           <main className={classes.main}>
            <ShippingContent current_shop = {props.current_shop} handle_snack = {handle_snackbar} />
            </main> 
           </Route>
           <Route path="/themes">
           <main className={classes.main}>
            <ThemeContent current_shop = {props.current_shop} handle_snack = {handle_snackbar}/>
            </main> 
           </Route>
           <Route path="/analytics">
           <main className={classes.mainAnalytics}>
             <Analytics current_shop = {props.current_shop} handle_snack = {handle_snackbar} />
             </main> 
           </Route>
           {/*
           <Route path="/documentation">
           <main className={classes.main}>
           </main>  
           </Route>
           */}
           <Route path="/">
           <main className={classes.main}>
             <Content current_shop = {props.current_shop} handle_snack = {handle_snackbar} current_header_val = {add_header_value} set_header_val = {set_add_header_value}/>
             </main> 
           </Route>
           </Switch>
       
        
          <footer className={classes.footer}>
            <Copyright />
          </footer>

        </div>
        
      </div>
      <CustomSnackbar open = {show_snackbar} handleBar = {handle_snackbar}></CustomSnackbar>
      {<Popper
        id={'123'}
        open={is_poper_open}
        disablePortal={true}
        onClose={handleClose}
        anchorReference="anchorPosition"
        anchorPosition={{ top: size.height, left: size.width}}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        modifiers={{
          flip: {
            enabled: false,
          },
          preventOverflow: {
            enabled: true,
            boundariesElement: 'scrollParent',
          },
          arrow: {
            enabled: false,
            element: props.arrowRef,
          },
        }}
      >

       <Feedback  current_shop = {props.current_shop} feedbackState={feedbackState} setFeedbackState = {setFeedbackState} handleFeedbackSubmit={handleFeedbackSubmit}></Feedback>
      
      </Popper> }
      </div>


    </ThemeProvider>
   
  );


  function get_shop_data(current_shop) {
    
    console.log('store_access_token')

    // user api

    // error handling and state managment required
  
    fetch('https://gt0425yc42.execute-api.eu-west-2.amazonaws.com/first/shop'+ current_shop.search,{
        crossDomain:true,
        method: 'GET',
        headers: {
          'store_domain': current_shop.shop
        }})
        .then(res => res.json())
        .then(
          (result) => {

            console.log(result)

            // copy by value
            // saving data to aws

            if(result.shop_data){

            set_shop_data(result.shop_data.shop)

             // copy by value

             // saving data to aws

             // const newObj = Object.assign({}, result.shop)

             //newObj['access_token'] = store_access_token

             //newObj['type'] = 'add_store'

             // To do readd
             //save_store_details_to_db(newObj)

            }else{
              // ToDo
              // display error
              console.log('Error')
            }

          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            console.log(error)
            set_shop_error(true)
          }
    )
  
  }


  function save_feedback_to_db(feedback_data,current_shop) {

    const new_obj = JSON.parse(JSON.stringify(feedback_data))

    new_obj.store_domain = current_shop.shop

    set_is_poper_open(false)

    //setisPopperLoading(true)
    
    fetch('https://gt0425yc42.execute-api.eu-west-2.amazonaws.com/first/feedback' + current_shop.search, {
      crossDomain:true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(new_obj)
    }).then(res => res.json()).then(
        (result) => {
         // setisPopperLoading(false)
          setFeedbackState({bn_needs: 0,browserState:'',easy_to_use:0,stable:0,issuesCheck:{'difficult': false,'crash':false,'deploy':false,'dataloss':false,'customization':false,'didnt':false},'detailsInput':"\n\n\n\n\n\n\n"})
          handle_snackbar('success', 'Feedback saved!', true)
        },
        (error) => {
          //setisPopperLoading(false)
          set_is_poper_open(true)
          handle_snackbar('error', 'Error saving feedback, try again!', true)
    })

}

}


Paperbase.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withRouter(withStyles(styles)(Paperbase));













// Efficient state handling and hooks

// use react memo

// when updating multiple states optimize to handle all

// Add request try catch to lambdas

// In lambda raise exception, return raise exception as json

// callback save to dynamo db

// lambda edge use route to add callback logic

// escape iframe on install

// make sure front end javascript location search is not an issue

// Global Context for hmac/location/auth

// User interface refinements

// centered response from Lambda edge just like "this is an embeded app....."

// set styles gives null for ckeditor check


// handle dynamo db errors


/*

<Router>
       <Switch>
           <Route path="/home">
            <About />
           </Route>
           <Route path="/users">
            <Users />
           </Route>
           <Route path="/">
           </Route>
            </Switch>
</Router>

*/

// history.goback

// hostory.replace


/*
    if(id === 'Sections'){
      
      temp_array[0]['children'] = temp_array[0]['children'].map(obj => {return {'id':obj.id,'icon':obj.icon}})
      temp_array[1]['children'] = temp_array[1]['children'].map(obj => {return {'id':obj.id,'icon':obj.icon}})
      const temp_obj = temp_array[0]['children'][index]
      temp_obj['active'] = true 
      current_id = temp_obj.id

    }
    else{

      temp_array[0]['children'] = temp_array[0]['children'].map(obj => {return {'id':obj.id,'icon':obj.icon}})
      temp_array[1]['children'] = temp_array[1]['children'].map(obj => {return {'id':obj.id,'icon':obj.icon}})
      const temp_obj = temp_array[1]['children'][index]
      temp_obj['active'] = true
      current_id = temp_obj.id
    }
*/



/*

    categories.forEach((section)=> {
    section.children.forEach((child)=>{
      if(child.active){
      switch (child.id) {

        case 'Add':
          set_header_title('New Section')
          break

        case 'Manage':
          set_header_title('Manage')

          break

        case 'Stores':
          set_header_title('Stores')

          break

        case 'Shipping':
          set_header_title('Shipping')
       
          break

        case 'Settings':
          set_header_title('Settings')
          break

        case 'Themes':
          set_header_title('Themes')
          break
        default:
          break

      }
    }
    });
    });
    */



// vv imp handle dynamo db errors every where

// Check override

// Manage -> live toggle, edit form

// Same analytics not matter which section chosen

// Manage and all timeouts

// Theme shouldn't be demo, so check permissions

// handle no theme manage sections

// check if app is deleted or not

// domain added so check for domain for analytics

// Segments to nest contacts  


// Store time in create section


// if theme not in edit then delete from previous theme


// Save doesnt deploys to shopify, Save and deploy saves and deploys to shopify


// Save and deploy

// Popup should close when clicked away from modal body'



// product images not showing, theme.scss file was changed

//http://localhost:3000/?hmac=c09f8a5b9fcea46ee28230f33de2428cccdeeb2808c21ffd588ba98dc68df2c5&locale=en-GB&session=37f8a5e3b2aba6c176112af0f555695ad3670906648d8ede8027b460267209c4&shop=areeb-the-best-store-2.myshopify.com&timestamp=1594899445


// API Gateway, get all stores with current email

// Get all combined shipping zones

// display shipping zones with store name

// Github, for shopify themes

// analytics timing out

// </body> tag can also be in end comments in theme.liquid


// check for webhooks execution etc