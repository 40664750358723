import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import RefreshIcon from '@material-ui/icons/Refresh';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress'
import EditIcon from '@material-ui/icons/Edit';
import ConfigureForm from '../ManageConfigure/ConfigureForm'
import countries from '../../datasets/countries'
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import PreviewModal from '../PreviewModal/PreviewModal';
import Container from '@material-ui/core/Container';
import Stores from '../AddStores/Stores'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

const headCells = [
  { id: 'id', numeric: false, disablePadding: true, label: 'Name' },
  { id: 'theme_name', numeric: true, disablePadding: false, label: 'Theme' },
  { id: 'created_at', numeric: true, disablePadding: false, label: 'Created on' },
  { id: 'updated_at', numeric: true, disablePadding: false, label: 'Updated on' },
  { id: 'is_live', numeric: true, disablePadding: false, label: 'Live' },
];

function EnhancedTableHead(props) {

  const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          {/*
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
          */ }
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {

  const classes = useToolbarStyles();

  const { numSelected } = props;


  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
          {numSelected} selected
        </Typography>
      ) : (
        <Typography className={classes.title} variant="h6" id="tableTitle" component="div">Sections</Typography>
      )}

      {numSelected > 0 ? (
        <div style= {{display:'flex'}}>
            <Tooltip title="Delete">
          <IconButton onClick = {props.handleDel} aria-label="delete">
            <DeleteIcon />
          </IconButton>
        </Tooltip>

      <Tooltip title="Edit">
        <IconButton onClick = {props.handleEdit} aria-label="Edit">
          <EditIcon />
        </IconButton>
      </Tooltip>

        </div>
      
      ) : (
        <Tooltip title="Filter list">
          <IconButton aria-label="filter list">
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
      },
      paper: {
        maxWidth: 936,
        margin: 'auto',
        overflow: 'hidden',
      },
  table: {
    minWidth: 750,
  },
  block: {
    display: 'block',
  },
  backdrop: {

    zIndex: theme.zIndex.drawer + 1,
    color: 'D43636',
  },
  addUser: {
    marginRight: theme.spacing(1),
  },
  backButton: {
    paddingLeft: '25px', paddingRight:'25px',
    marginLeft: theme.spacing(1),
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  cardGrid: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  backPBar:{
    marginTop: '100px',
    marginBottom: '100px',
    marginLeft: '10%',
    position:'relative',
    color: 'white',
  },
  pBar:{
    marginTop: '100px',
    marginBottom: '100px',
    marginLeft: '48%',
    position:'relative',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },contentWrapper: {
    margin: '16px',
  },
}));

export default function Manage(props) {

const isMountedRef = useRef(null);
// From here  

const [isLoading, setIsLoading] = React.useState(false)

const [isStoreLoading, setIsStoreLoading] = React.useState(false)

const [isBackLoading, setIsBackLoading] = React.useState(false)


const [is_select_disabled, set_is_select_disabled] = React.useState(false)

const [rows, set_sections] = React.useState([])

const [selected, setSelected] = React.useState([])

const [isEditing, setIsEditing] = React.useState(false)

const [all_shipping_zones, set_shipping_zones] = React.useState([])

const [selected_shipping_zones, setSelectedShippingZones] = React.useState([]);

const [all_themes, setAllThemes] = React.useState([]);

const [selected_themes, setSelectedThemes] = React.useState([]);

const [locations, setLocations] = React.useState(countries);

const [selected_locations, set_selected_locations] = React.useState([]);

const [section_view_data, set_section_view_data] = React.useState({})

const [open, setOpen] = React.useState({'open_state':false, 'type':''});

const [form_body, set_form_body] = React.useState('');

const [section_name,set_section_name] = React.useState('');

const [ck_state, set_ck_state] = React.useState('')

const [style_sheet, set_style_sheet] = React.useState("")

const classes = useStyles();

const [all_stores, set_stores] = React.useState([])

const [selected_stores, setSelectedStores] = React.useState([])

const [order, setOrder] = React.useState('asc');

const [orderBy, setOrderBy] = React.useState('id');

const [page, setPage] = React.useState(0);

const [rowsPerPage, setRowsPerPage] = React.useState(5);

const [radioState, setRadioState] = React.useState('Whitelist');

const [slider_value, set_slider_value] = React.useState(0)

const [edit_section_data, set_edit_section_data] = React.useState({})

const reset_state = () =>{
  setSelected([])
  set_shipping_zones([])
  set_sections([])
  setSelectedStores([])
  setIsLoading(false)
  setIsStoreLoading(false)
  setIsBackLoading(false)
  setSelectedShippingZones([])
  setSelectedThemes([])
  set_ck_state('')
  setIsEditing(false)
  set_style_sheet('')
  setOpen({'open_state':false, 'type':''})
  set_slider_value(0)
  set_section_name('')
}

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleDelete = (index) => { 

    /*
    var name = selected[0]
    const asset_name = name
    const  index = rows.findIndex(x => x.id == name)


    if(index > -1){

    name = name.replace('snippets/','')

    name = name.replace('.liquid','')

    const theme_id = rows[index].theme_id

    delete_section(props.current_shop, name, asset_name ,index, theme_id)

    }else{
        props.handle_snack('error', 'Invalid Index',true)
        setSelected([])
    }
    */

   delete_section(props.current_shop, rows[index], rows[index].store_domain,index)

    //setSelected([]);
  }


  const handleSliderChange = (event, newValue) => {
    set_slider_value(newValue)
  };


  const handleEdit = (index) => { 
  

    /*
    var name = selected[0]

    const asset_name = name

    const  index = rows.findIndex(x => x.id == name)

    console.log(rows[index])
    

    if(index > -1){
      get_edit_section(props.current_shop,rows[index].theme_id, rows[index].key, rows[index].theme_name)
    }   
*/

    get_edit_section(props.current_shop,rows[index].store_domain, rows[index].id, rows[index].theme_name)
    console.log(rows[index])

  }

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.id);
      setSelected(newSelecteds);
      console.log('Set selected',newSelecteds)
      return;
    }
    setSelected([]);
  };


  const handle_deploy = (type) => {

        save_form_to_shopify(section_name, selected_themes, selected_locations, ck_state, radioState,slider_value,type,edit_section_data,style_sheet)

    }

    const back_clicked = (event) => {
      setIsEditing(false)

  }

const handleSectionOpen = (dt,st) => {
  console.log('handling open')
  set_section_view_data({'dt':dt,'st':st})
  setOpen({'open_state':true, 'type':'section'});
}


const handleStyleSheetChange = (event) => {
  set_style_sheet(event.target.value)
}

      
  const handleClose = () => {
    setOpen({'open_state':false, 'type':''});
 };



  const handleClick = (event, name) => {

     
    const selectedIndex = selected.indexOf(name);

    console.log(selectedIndex)
    
    let newSelected = [];


    if (selectedIndex === -1) {
      newSelected = [name]
      //newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
       newSelected = []
     // newSelected = newSelected.concat(selected.slice(1));
    } 
    
    /*else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1),
      );
    }
    */
   // console.log('handle Click',newSelected)
    setSelected(newSelected);
    

  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);


  const refresh_clicked = (event) => {
    reset_state()
    get_all_stores(props.current_shop)
};


const handle_store_click = (store_domain) => {

  if(!isLoading){
  setSelectedStores(store_domain)
  if(store_domain.length > 0){
  get_all_sections(props.current_shop, store_domain[0])
  }else{
    set_sections([])
    setSelected([])
  }
  }
}


const handleSwitchChange = (index,event) => {

  //const current_rows = [...rows]

  //const theme_id = current_rows[index].theme_id

  //const section_name = current_rows[index].key


  if(!rows[index].is_stored){
    props.handle_snack('error','Please deploy this section to a Theme in Edit Section!',true)
    return 
  }

  set_live(props.current_shop, rows[index],event.target.checked,index)

}




    useEffect(()=>{
      isMountedRef.current = true
      get_all_stores(props.current_shop)
      //get_all_sections(props.current_shop)
      return () => isMountedRef.current = false;
    },[])

  

    return (
        <div>   
        <Paper className={classes.paper}>
          <AppBar style={{borderBottom: '1px solid rgba(0, 0, 0, 0.12)'}} position="static" color="default" elevation={0}>
            <Toolbar>
            <Grid container spacing={2} alignItems="center">

            {isEditing === true ?  <Grid item><Button variant="contained" color="primary" className={classes.backButton} onClick={back_clicked}>
                  {"Back"}
                 </Button> </Grid>

                 : null}
                 {false?
               <Grid item>
                  <SearchIcon className={classes.block} color="inherit" />
               </Grid> : null
                 }
                <Grid item xs> 
                  <TextField
                    fullWidth
                    disabled = { true}
                    placeholder= { ""}
                    InputProps={{
                      disableUnderline: true,
                      className: classes.searchInput,
                    }}
                  />
                </Grid> 
               
                <Grid item>

                {/*isEditing === true ? <Button variant="contained" color="primary" className={classes.addUser} onClick={next_clicked}>
                  {"Deploy"}
                 </Button> 
                 
                  : null*/}
                  <Tooltip title="Reload">
                    <IconButton onClick = {refresh_clicked.bind(this)}>
                      <RefreshIcon className={classes.block} color="inherit" />
                    </IconButton>
                  </Tooltip>
                </Grid>
              </Grid>
            </Toolbar>
          </AppBar>
    
      <div className={classes.contentWrapper}>

        { isEditing == true ? null : isStoreLoading == true ? <CircularProgress className = {classes.pBar} /> : <Stores handle_store_click = {handle_store_click} selected = {selected_stores} rows = {all_stores}></Stores> }
        
        {isLoading == true ? <CircularProgress className = {classes.pBar} /> : isEditing == true ? <ConfigureForm handle_snack = {props.handle_snack} is_select_disabled = {is_select_disabled} style_sheet = {style_sheet} handleStyleSheetChange = {handleStyleSheetChange} handle_deploy = {handle_deploy} all_themes = {all_themes} slider_value = {slider_value} handleSliderChange={handleSliderChange} ck_state = {ck_state} set_ck_state = {set_ck_state}  shp_zones = {all_shipping_zones} sel_shp_zones = {selected_shipping_zones} sel_themes = {selected_themes} set_selected_themes = {setSelectedThemes}  selected_locations = {selected_locations} set_selected_locations = {set_selected_locations} locations= {locations}  setLocations= {setLocations} open = {open} setOpen = {setOpen}  section_name = {section_name} align="center"></ConfigureForm> : (selected_stores.length > 0 && rows.length > 0) ?

        <div>

<hr style={{marginLeft:'2%',marginRight:'2%',marginBottom:'1%'}}></hr>     
        <EnhancedTableToolbar handleEdit = {handleEdit} handleDel = {handleDelete} numSelected={selected.length} />

        <Container className={classes.cardGrid} maxWidth="md">

          <Grid container spacing={4}>

            {rows.map((card,index) => (
              <Grid item key={card.id} xs={12} sm={6} md={4}>
                
                <Card variant="elevation" elevation={6} className={classes.card}>
                 { /*<CardMedia
                    className={classes.cardMedia}
                    image={card.url}
                    title="Image title"
                    <div style={{width:'100%',height:'50%',overflow: 'hidden', border:'1px solid black'}} dangerouslySetInnerHTML={ { __html: "<div style = 'height:100%;width:100%; position: relative; top: 50%; left: 50%;transform: translateX(-50%) translateY(-50%);'>" +card.template_body +"</div>"} }></div>   

                 />*/}
               <Paper square = {true} variant='elevation' elevation={2} style={{borderBottomLeftRadius:'120px 40px',borderBottomRightRadius:'120px 40px',overflow: 'hidden',backgroundColor: '#f8f7fc'}}><iframe  style={{width:'100%',height:'200px',overflow: 'hidden', border: '1px solid #f8f7fc','borderRadius': '10px 10px 10px 10px', 'WebkitTransform' : 'scale(0.75,0.6)','MozTransformScale(0.75,0.6)':'','objectFit': 'fill'}} srcDoc={"<html><head><style type='text/css'>"+ card.style_sheet + "</style></head><body>"+"<div style='object-fit: fill'>"+card.editor_data+"</div>"+"</body>"} ></iframe></Paper>

                  <CardContent className={classes.cardContent}>
                    <Typography style = {{fontWeight: 'bold'}}variant="h6" component="h2">
                      {card.section_name}
                    </Typography>
                    <Typography variant = "body2">
                    {card.themes ? card.themes.map(obj=> obj.name).toString() : ""}
                    </Typography>
                  </CardContent>
                  <div style={{marginTop: '6px',display: 'flex'}}><Button size="small" color="primary" onClick={(event) => handleSectionOpen(card.editor_data,card.style_sheet)}>
                      View
                    </Button>
                    <Button size="small" color="primary" onClick={(event) => handleEdit(index) }>
                      Edit
                    </Button>
                    <Button size="small" color="primary" onClick={(event) =>  handleDelete(index) }>
                      Delete
                    </Button>
                    <div style={{marginLeft:'3%'}}><Switch
                    
                    checked={card.is_live}
                    color="primary"
                    name="checkedB"
                    onChange= {handleSwitchChange.bind(this,index)}
                    inputProps={{ 'aria-label': 'primary checkbox'}}
                  /></div>
                    </div>
                  {/*<CardActions style={{marginTop: '6px'}}>
                    
                  </CardActions>*/}
                </Card>
              </Grid>
            ))}
          </Grid>

        </Container>

        {/*<TableContainer>
          <Table
            className={classes.table}
            aria-labelledby="tableTitle"
            size={'medium'}
            aria-label="enhanced table"
          >
            <EnhancedTableHead
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          onClick={(event) => handleClick(event, row.id)}
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.id}
                      </TableCell>
                      <TableCell align="right">{row.theme_name}</TableCell>
                      <TableCell align="right">{row.created_at}</TableCell>
                      <TableCell align="right">{row.updated_at}</TableCell>
                      <TableCell align="right"><Switch
        edge = 'end'
        checked={row.is_live}
        color="primary"
        name="checkedB"
        onChange= {handleSwitchChange.bind(this,index)}
        inputProps={{ 'aria-label': 'primary checkbox'}}
      /></TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
              </TableContainer>*/}
       {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onChangePage={handleChangePage}
          onChangeRowsPerPage={handleChangeRowsPerPage}
       /> */}
        </div> : null}
      </div>
        </Paper>
        <PreviewModal style_sheet= {style_sheet} section_view_data = {section_view_data} ck_editor_data = {ck_state} open = {open}  handleClose = {handleClose}  selected_locations = {locations}></PreviewModal>
        <Backdrop className={classes.backdrop} open={isBackLoading} >
        <CircularProgress className = {classes.backPBar}/>
          </Backdrop>
        </div>
      );


   function set_live(current_shop, section,checked,index){

    console.log('called Sections')
  
    setIsLoading(true)

    //const body = {'store_domain': current_shop.shop,'theme_id':theme_id, 'section_name': section_name.split('snippets/')[1].split('.liquid')[0], 'checked': checked}

    const body = {'store_domain': section.store_domain, 'section': section, 'checked': checked}

    console.log(JSON.stringify(body))
  
    fetch('https://gt0425yc42.execute-api.eu-west-2.amazonaws.com/first/sections/live'+current_shop.search,{
        crossDomain:true,
        method: 'PUT',
        body:  JSON.stringify(body)
      })
        .then(res => res.json())
        .then(
          (result) => {

            if(isMountedRef.current){
            setIsLoading(false)
          
            const current_rows = [...rows]
            if(result.message == 'success'){
              /*
              update_code
              if(checked){
              for (var i = 0; i < current_rows.length; i++) {
                if(i != index && current_rows[i].theme_id == current_rows[index].theme_id){
                  current_rows[i]['is_live'] = false
                }
              }
            }
            */

               current_rows[index]['is_live'] = checked

               set_sections(current_rows)

            }else{
              props.handle_snack('error','Error setting status',true)
              current_rows[index]['is_live'] = !checked
              set_sections(current_rows)
            }
          }
            console.log(result)
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            if(isMountedRef.current){
            setIsLoading(false)
            props.handle_snack('error','Error setting status',true)
            console.log(error)
            }
            //set_shipping_error(true)
          }
    )
  
   }


  function get_all_sections(current_shop, store_domain) {

    console.log('called Sections')


    set_sections([])

    
  
    setIsLoading(true)

  
    fetch('https://gt0425yc42.execute-api.eu-west-2.amazonaws.com/first/sections' + current_shop.search,{
        crossDomain:true,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'store_domain': store_domain
        }
      })
        .then(res => res.json())
        .then(
          (result) => {

            if(isMountedRef.current){
            setIsLoading(false)
            if(result.sections){
                if(result.sections.length > 0){
                  set_sections(result.sections)
                }else{
                  props.handle_snack('error','No Sections yet',true)
                }
            }else{
              props.handle_snack('error','Error fetching Sections',true)
            }
          }
            console.log('sections were',result)
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            if(isMountedRef.current){
            props.handle_snack('error','Error fetching Sections',true)
            setIsLoading(false)
            console.log(error)
            }
            //set_shipping_error(true)
          }
    )
  
  }



  function delete_section(current_shop, section_data, store_domain, index) {
  
    setIsLoading(true)

    const body = {'store_domain': store_domain,
    'section_data': section_data
    //'asset_name': asset_name,
   // 'section_name': section_name,
    //'theme_id': theme_id.toString()
  }

  console.log(body)

    fetch('https://gt0425yc42.execute-api.eu-west-2.amazonaws.com/first/sections' + current_shop.search,{
        crossDomain:true,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body)
      })
        .then(res => res.json())
        .then(

          (result) => {

            if(isMountedRef.current){

            setIsLoading(false)

            if(result.message){
              props.handle_snack('success','Section successfully deleted',true)
              const current_rows = [...rows]
              current_rows.splice(index, 1)
              setSelected([])
              set_sections(current_rows)
            }else if(result.error_message){
              console.log(result)
              var error_message = 'Error deleting section'
              if(result.error_message){
                error_message = error_message + result.error_message
              }
              props.handle_snack('error',error_message,true)
            }else{
              props.handle_snack('error','Error with API',true)
            }
          }
            console.log(result)

          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            if(isMountedRef.current){
            props.handle_snack('error','Error with API',true)
            setIsLoading(false)
            console.log(error)
            }
            //set_shipping_error(true)
          }
    )
  
  }

  function get_edit_section(current_shop, store_domain, key, theme_name) {

  
    setIsLoading(true)
  
    fetch('https://gt0425yc42.execute-api.eu-west-2.amazonaws.com/first/sections/edit' + current_shop.search,{
        crossDomain:true,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'store_domain': store_domain,
          'key': key
        }
      })
        .then(res => res.json())
        .then(
          (result) => {

            if(isMountedRef.current) {

            setIsLoading(false)

            if(result.body && result.shp_zones){

              console.log('result',result)
     
                //set_sections(result.sections)
                set_ck_state(result.body)

                setIsEditing(true) 


                const tme_wwe = [...result.themes]
                
                const tme_dep = [...result.dep_themes]

                var result1 = tme_wwe.filter(function(o1){

                  return tme_dep.some(function(o2){
                      return o1.id === o2.id;          
                  });
                  
              });


                setAllThemes(tme_wwe)

                setSelectedThemes(result1)

                //setSelectedThemes(result.dep_themes.map(obj=>({'id':obj.id.toString(),'name':obj.name.toString()})))

                /*

                if(result.section_data.theme_id) {

                var temp_rows = [...result.themes]

                temp_rows = temp_rows.map(obj => '' + obj.id )

                console.log('rows were', temp_rows)
              
                const index = temp_rows.indexOf(result.section_data.theme_id)

                console.log('index was', index)

                if(index > -1){
                  set_is_select_disabled(true)
                  setSelectedThemes([result.themes[index]])

                }
                else{
                  setSelectedThemes([])
                  set_is_select_disabled(false)
                }


                }

                */




                set_edit_section_data(result)

                set_style_sheet(result.section_data.style_sheet)

                //set_section_name(key.split('snippets/dataSaurGeoLocation_')[1].split('.liquid')[0])

                set_section_name(result.section_data.section_name)

                if(result.remember_for_days && result.remember_for_days.length > 0){

                  try{

                    var x = Number(result.remember_for_days)

                    set_slider_value(x)

                  }catch(e){

                  }

                }

                if(result.shp_zones.indexOf('Whitelist') > -1){

                  const index = result.shp_zones.indexOf('Whitelist');
                  result.shp_zones.splice(index, 1);
                  setRadioState('Whitelist')

                }else if(result.shp_zones.indexOf('Blacklist') > -1){

                  const index = result.shp_zones.indexOf('Blacklist');
                  result.shp_zones.splice(index, 1);
                  setRadioState('Blacklist')

                }

                setSelectedShippingZones(result.shp_zones)

            }else{

              props.handle_snack('error','Error fetching section!',true)

            }
            }else{
                //error
            }

            console.log(result)
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            if(isMountedRef.current){
            setIsLoading(false)
            props.handle_snack('error','Error fetching sections!',true)
            console.log(error)
            }
            //set_shipping_error(true)
          }
    )
  
  }



  function get_hash_code(s) {
    var hash = 0, i, chr;
    for (i = 0; i < s.length; i++) {
      chr   = s.charCodeAt(i);
      hash  = ((hash << 5) - hash) + chr;
      hash |= 0; // Convert to 32bit integer
    }
    return hash;
  }
  

  function save_form_to_shopify(section_name,theme_data,shipping_data, editor_data,radioState,slider_value, type, previous_section_data, style_sheet) {

  
    if(section_name === "" || shipping_data.length === 0 || editor_data.trim().length === 0) {

      props.handle_snack('error','Please complete section details!',true)

    } else {



      /*

    var theme_id = -1

    var theme_name = ""

    if (theme_data.length == 0 && type == "deploy") {
      props.handle_snack('error','Please select a valid Theme!',true)
      return
    }

    if (theme_data.length != 0) {

      var thm;

      theme_id = theme_data[0].id

      for (thm in all_themes){
        if(all_themes[thm].id == theme_id){
          theme_name = all_themes[thm].name
          break
        }
      }

    }

    */

    if(theme_data.length == 0){
      props.handle_snack('error','Please selected one or more themes!',true)
      return
    }


    const store_data = {}

    const name_hash = get_hash_code(section_name.trim())

    store_data['section_name'] = section_name
    store_data['section_hash'] = name_hash
    //store_data['theme_id'] = theme_id
    //store_data['theme_name'] = theme_name
    store_data['themes'] = theme_data
    store_data['shipping_data'] = shipping_data
    store_data['store_domain'] = previous_section_data.section_data.store_domain
    store_data['editor_data'] = editor_data
    store_data['radioState'] = radioState
    store_data['remember_for_days'] = slider_value
    store_data['type'] = type
    store_data['previous_section_data'] = previous_section_data.section_data
    store_data['previous_edit_section_data'] = previous_section_data
    store_data['style_sheet'] = style_sheet.trim()



    if(style_sheet.trim().length > 0){
      if(!(/^(([a-z0-9\[\]=:]+\s?)|((div|span)?(#|\.){1}[a-z0-9\-_\s?:]+\s?)+)(\{[\s\S][^}]*})$/mi.test(style_sheet.trim())) || style_sheet.includes('<style') || style_sheet.includes('</style>')){
        props.handle_snack('error','Invalid Style Sheet!',true)
        return
      }
      //console.log(/^(([a-z0-9\[\]=:]+\s?)|((div|span)?(#|\.){1}[a-z0-9\-_\s?:]+\s?)+)(\{[\s\S][^}]*})$/mi.test(style_sheet.trim))
    }

    setIsBackLoading(true)


    console.log(store_data)


    
    fetch('https://gt0425yc42.execute-api.eu-west-2.amazonaws.com/first/sections/edit' + props.current_shop.search, {
      crossDomain:true,
      method: 'POST',
      headers: {
        'Content-Type': 'application/json;charset=utf-8'
      },
      body: JSON.stringify(store_data)
    }).then(res => res.json()).then(
        (result) => {
          if(isMountedRef.current){
          setIsBackLoading(false)
          if(!result.errorMessage){
            props.handle_snack('success','Form successfully updated',true)
            reset_state()
          }else{
            props.handle_snack('error',result.errorMessage.toString(),true)
          }
        }
          console.log(result)
          //set_shop_data(result.shop)
        },
        // Note: it's important to handle errors here
        // instead of a catch() block so that we don't swallow
        // exceptions from actual bugs in components.
        (error) => {
          if(isMountedRef.current){
          setIsBackLoading(false)
          props.handle_snack('error',error.toString(),true)
          console.log(error)
          }
         // set_shop_error(true)
        }
  )

   }
  
  }

  function get_all_stores(current_shop) {
  
    setIsStoreLoading(true)
  
    fetch('https://gt0425yc42.execute-api.eu-west-2.amazonaws.com/first/connected-stores'+ current_shop.search, {
        crossDomain:true,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'store_domain': current_shop.shop
        }
      }).then(res => res.json())
        .then(
          (result) => {
  
            console.log('stores were',result)
            if(isMountedRef.current){
  
              setIsStoreLoading(false)
  
              if(result.connected_stores){
  
                set_stores(result.connected_stores)
  
            }else{
  
              props.handle_snack('error','Error fetching stores!',true)
  
            }
  
            }
    
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            if(isMountedRef.current){
              props.handle_snack('error','Error fetching stores!',true)
              setIsStoreLoading(false)
            }
            console.log(error)
            //set_shipping_error(true)
          }
    )
  
  }
}


/*
Manage.propTypes = {
  classes: PropTypes.object.isRequired,
};
*/

//export default withStyles(styles)(Manage);

