import React,{useEffect, useState, useRef}  from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Box from '@material-ui/core/Box';

import TextField from '@material-ui/core/TextField';



const useRowStyles = makeStyles({
    root: {
      '& > *': {
        borderBottom: 'unset',
      },
    },
  
});




const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));


  
  function Row(props) {

    const { row } = props;
    const index = props.index
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
  
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell style={{paddingRight:'0'}}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell >
          <Checkbox
          onClick = {(event) => props.handleAllClick(event, row.store_id, index)}
          style={{paddingRight:'0'}}
            checked={row.is_checked }
            onChange={props.onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
          <TableCell component="th" scope="row">
            {row.store_name}
          </TableCell>
         { /*<TableCell align="right">{row.store_name}</TableCell>*/}
          {<TableCell align="right">{row.store_domain}</TableCell>}
          <TableCell align="right">{row.store_email}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Shipping Zones
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                    <TableCell padding="checkbox"></TableCell>
                      <TableCell>Name</TableCell>
    {/* <TableCell align="right">Name</TableCell> */}
                      <TableCell align="right">Country</TableCell>
                      <TableCell align="right">Code</TableCell>
                      <TableCell align="right">Tax Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.shipping_zones.map((historyRow,index2) => (
                      <TableRow  tabIndex={-1} role="checkbox" hover key={historyRow.id}>

                      <TableCell padding="checkbox">
                        <Checkbox
                        onClick = {(event) => props.handleClick(event, historyRow.id,index,index2)}
                          checked={historyRow.is_checked}
                          inputProps={{ 'aria-labelledby': props.labelId }}
                        />
                      </TableCell>
                        <TableCell component="th" scope="row">
                          {historyRow.name}
                        </TableCell>
                       { /*<TableCell align="right">{historyRow.name}</TableCell>*/}
                        <TableCell align="right">{historyRow.country}</TableCell>
                        <TableCell align="right">
                          {historyRow.code}
                        </TableCell>
                        <TableCell align="right">
                          {historyRow.tax_name}
                        </TableCell>
                      </TableRow>

                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
}

export default function NestedShippingZones(props) {

  const rows = props.rows



  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('id');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  useEffect(() => {

    console.log('nested',props.nested_rows)

    console.log('selected',props.selected)

  }, [props.nested_rows])

  

  const handleClick = (event, name,index, index2) => {

    const selectedIndex = props.selected.indexOf(name);

    const pp_selected = [...props.selected]
    
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(pp_selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(pp_selected.slice(1));
    } else if (selectedIndex === pp_selected.length - 1) {
      newSelected = newSelected.concat(pp_selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        pp_selected.slice(0, selectedIndex),
        pp_selected.slice(selectedIndex + 1),
      );
    }

    console.log('handle Click',newSelected)

    const temp_obj = [...props.nested_rows]

    temp_obj[index].shipping_zones[index2].is_checked = event.target.checked

    props.set_nested_rows(temp_obj)

    props.setSelected(newSelected);

  };


  const handleAllClick = (event, name,index) => {

    const is_chck = event.target.checked

    console.log(event.target.checked, name,index)

    const temp_obj = [...props.nested_rows]

    temp_obj[index].is_checked = is_chck

    temp_obj[index].shipping_zones.forEach((shp_zone)=>{
      shp_zone.is_checked = is_chck
    });


    var temp_selected = [...props.selected]

    if(is_chck){
      temp_selected = temp_selected.concat(temp_obj[index].shipping_zones.map(obj=>obj.id))
    }else{

    temp_selected = temp_selected.filter(function(o1){

    return temp_obj[index].shipping_zones.map(obj=>obj.id).indexOf(o1) === -1;   

  })
    }
    props.set_nested_rows(temp_obj)
    props.setSelected(temp_selected)

    /*
    const selectedIndex = props.selected.indexOf(name);
    
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(props.selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(props.selected.slice(1));
    } else if (selectedIndex === props.selected.length - 1) {
      newSelected = newSelected.concat(props.selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        props.selected.slice(0, selectedIndex),
        props.selected.slice(selectedIndex + 1),
      );
    }
    console.log('handle Click',newSelected)
    props.setSelected(newSelected);
*/
  };


  return (
    <div className={classes.root}>
    <TableContainer>
      <Table className={classes.table}  aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell style={{paddingRight:'0'}}/>
            <TableCell style={{paddingRight:'0'}}/>
            <TableCell>Store Name</TableCell>
            {/*<TableCell align="right">Store Name</TableCell>*/}
           { <TableCell align="right">Store Domain</TableCell>}
            <TableCell align="right">Email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {props.nested_rows.map((row,index) => (
            <Row index = {index} handleAllClick = {handleAllClick} handleClick={handleClick} key={row.store_id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>
  );
}
/*
<TableContainer>
<Table
  className={classes.table}
  aria-labelledby="tableTitle"
  size={'medium'}
  aria-label="enhanced table"
>
<EnhancedTableHead
              classes={classes}
              numSelected={props.selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
            />
            <TableBody>
              {stableSort(rows, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    
                    <TableRow
                      hover
                      onClick={(event) => handleClick(event, row.id)}
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell component="th" id={labelId} scope="row" padding="none">
                        {row.id}
                      </TableCell>
                      <TableCell align="right">{row.name}</TableCell>
                      <TableCell align="right">{row.store_name}</TableCell>
                      <TableCell align="right">{row.country}</TableCell>
                      <TableCell align="right">{row.code}</TableCell>
                      <TableCell align="right">{row.tax_name}</TableCell>
                    </TableRow>
                  );
                })}
              {emptyRows > 0 && (
                <TableRow style={{ height: (53) * emptyRows }}>
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>


            */