

import CustomLocation from '../CustomLocation/CustomLocation';

//import CKEditor from '@ckeditor/ckeditor5-react';
//import ClassicEditor from '@ckeditor/ckeditor5-build-classic'



import React, {  useEffect} from 'react';


import { Grid,  FormControl, Input, InputLabel, FormHelperText, Select, MenuItem,  makeStyles, useTheme, Chip,  Button} from '@material-ui/core';

import CKEditor from 'ckeditor4-react';

import Slider from '@material-ui/core/Slider';

import Typography from '@material-ui/core/Typography';

import TextField from '@material-ui/core/TextField';

import OutlinedDiv from "../OutlinedDiv/OutlinedDiv";

CKEditor.editorUrl = '/ckeditor-4/ckeditor.js'


const marks = [
  {
    value: 0,
    label: '0 days',
  },
  {
    value: 7,
    label: '7 days',
  },
  {
    value: 14,
    label: '14 days',
  },
  {
    value: 28,
    label: '28 days',
  },
];

function valuetext(value) {
  return `${value} days`;
}


const useStyles = makeStyles((theme) => ({


  root: {
    flexGrow: 1,
  },

  title: {
    flexGrow: 1,
    marginLeft: '2px',
    marginTop: '9px',
   'font-family': '\'Fira Sans\', sans-serif',
   'font-weight' : '500',
   'font-size': '28px',
  },
  formControl: {
    width:'98%',
    minWidth: 200,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    marginTop: '12px',
    width: '50%',
  },
  appBar: {
    background : '#20c2e8',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(2),
  },
}));

const ITEM_HEIGHT = 48;

const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function getStyles(name, country_names, theme) {

  return {
    fontWeight:
    country_names.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };

}



function handleChangeBinded(index, name, e){
 
  console.log(index)

  console.log(name)

  console.log(e.target.value)

}

 
function ConfigureForm(props) {

 

  const theme = useTheme();

  const classes = useStyles();



  const handleOpen = () => {
    props.setOpen({'open_state':true, 'type':'ck'});
  };



  useEffect(() => {


    var is_star = false

    console.log('shp effect',props.sel_shp_zones)

    const cur_shp_zones = [];


    props.locations.forEach((location)=>{
      props.sel_shp_zones.forEach((sh_z)=>{
        if(sh_z == "*"){
          is_star = true
        }
        if(location.code == sh_z){
          cur_shp_zones.push(location)
        }
      });
    });

    if(!is_star){
    props.set_selected_locations(cur_shp_zones)
    }else{
    props.set_selected_locations([...props.locations])
    }

    




/*
    const changed_shp_zones = [...props.sel_shp_zones]

    const new_zones = [...props.shp_zones]

    var result = new_zones.filter(function(o1){

      return changed_shp_zones.some(function(o2){
          return o1.id === o2;          
      });
  })


  result.map(obj=> obj['color'] = '#FFFFFF')

  const previous_locations = [...props.locations]

  var result2 = previous_locations.filter(function(o1){

    return result.some(function(o2){
        return o1.id === o2.id;         
    });

  })

  result.map(obj => result2.find(o => o.id === obj.id) || obj)

  props.setLocations(result)

*/

  },[props.sel_shp_zones]);


  const handleThemeChange = (event) => {

    //var temp_rows = [...props.all_themes]

   // temp_rows = temp_rows.map(obj => obj.name)

    //const index = temp_rows.indexOf(event.target.value)

    //console.log(props.all_themes[index])

    if(event.target.value.length <= 5){

      console.log('val was',event.target.value)

      props.set_selected_themes(event.target.value)
  

    }else{

      props.handle_snack('error','You can select at most 5 Themes!',true)
      
    }

  
    //const index = temp_rows.map(obj => obj.name).indexOf(event.target.value)

    //props.set_selected_themes(props.all_themes[index])
    /*
    const options = [...event.target.value];
    
    const value = [];

    for (let i = 0, l = options.length; i < l; i += 1) {
        value.push(options[i]);
    }

    const final_val = [];

    props.locations.forEach((location)=>{
      value.forEach(val=>{
        if(val == location.country){
          final_val.push(location)
        }
      });
    });

    props.set_selected_locations(final_val)
*/

  };


  const handleChange = (event) => {

    const options = [...event.target.value];
    

    const value = [];

    for (let i = 0, l = options.length; i < l; i += 1) {
        value.push(options[i]);
    }

    const final_val = [];

    props.locations.forEach((location)=>{
      value.forEach(val=>{
        if(val == location.country){
          final_val.push(location)
        }
      });
    });

    
    props.set_selected_locations(final_val)
 
  
    

  };


  return (

    <div className={classes.root}>

      <main className={classes.content}>

      <Grid container direction="row" spacing={2} justify="flex-start" alignItems="center" style = {{margin:'auto',padding: '0px'}}>

      <Grid item xs={12}>

      <FormControl className={classes.formControl}>

      <InputLabel style= {{paddingTop: "0px"}} htmlFor="section_name">Section Name</InputLabel>

      <Input id="section_name" disabled = {true}  aria-describedby="section-helper-text" value = {props.section_name} onChange={event => props.set_section_name(event.target.value)}/>

     <FormHelperText id="section-helper-text">This will be saved as an Asset in your Theme File</FormHelperText>

     </FormControl>

     </Grid>

     {
       /*
      <Grid item xs={12}>

      <FormControl className={classes.formControl}>

      <InputLabel htmlFor="popup_heading">Popup Heading</InputLabel>

      <Input id="popup_heading" aria-describedby="popup_heading_helper_text" value = {props.form_title} onChange={event => props.set_form_title(event.target.value)} />

      <FormHelperText id="popup_heading_helper_text">Describe the title of your Popup</FormHelperText>

      </FormControl>

      </Grid>

      <Grid item xs={12}>

      <FormControl  className={classes.formControl}>

      <InputLabel htmlFor="popup_description">Popup Description</InputLabel>

      <Input id="popup_description"  aria-describedby="popup_description_helper_text" value = {props.form_body} onChange={event => props.set_form_body(event.target.value)}/>

      <FormHelperText id="popup_description_helper_text">Describe the actions of your Popup</FormHelperText>

      </FormControl>

      </Grid>
*/
     }

      <Grid item xs={12}>

<FormControl className={classes.formControl}>

   <InputLabel id="demo-mutiple-theme-chip-label">Themes</InputLabel>
   <Select 
     labelId="demo-mutiple-theme-chip-label"
     id="demo-mutiple-theme-chip"
     aria-describedby="themes-helper-text"
     multiple
     value={props.sel_themes}
     input={<Input id="select-multiple-theme-chip" />}
     onChange={handleThemeChange}
     renderValue={(selected) => (
       <div className={classes.chips}>
         {selected.map((value) => (
           <Chip key={value.id} label={value.name} className={classes.chip} />
         ))}
       </div>
     )}
     MenuProps={MenuProps}
   >
     {props.all_themes.map((themee) => (

       <MenuItem key={themee.id} value={themee} style={getStyles(themee.name,props.sel_themes, theme)}>
         {themee.name}
       </MenuItem>

     ))}


   </Select>


 <FormHelperText id="themes-helper-text">Selected Themes</FormHelperText>

 </FormControl>

 </Grid>
{/*  // here */ }
     <Grid item xs={12}>

     <FormControl  className={classes.formControl}>

        <InputLabel id="demo-mutiple-chip-label" >Locations</InputLabel>

        <Select
          labelId="demo-mutiple-chip-label"
          id="demo-mutiple-chip"
          aria-describedby="locations-helper-text"
          multiple
          value={props.selected_locations.map(obj=> obj.country)}
          onChange={handleChange}
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (

            <div className={classes.chips}>
              
              {selected.map((value) => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}

            </div>

          )}
          MenuProps={MenuProps}
        >
          {props.locations.map((location) => (

            <MenuItem key={location.country} value={location.country} style={getStyles(location.country, props.locations, theme)}>
              {location.country}
            </MenuItem>

          ))}

          { /*onChange={ handleChange.bind(this, -1, 'None') }*/}

        </Select>

     
      <FormHelperText id="locations-helper-text">Selected Countries</FormHelperText>

      </FormControl>

      </Grid>

      <Grid item xs={12}>
        <div style={{width:'98%'}}>
      <Typography style={{color:'Gray', padding:'0px',marginBottom:'0px'}} variant="body2"  gutterBottom>
        Remember close state
      </Typography>
      <div  style = {{marginLeft:'0.75%',marginRight:'0.5%',marginTop:'0px',marginBottom:'0px',  padding:'0px'}}>
      <Slider
        onChange={props.handleSliderChange}
        value = {props.slider_value}
        getAriaValueText={valuetext}
        aria-labelledby="discrete-slider-always"
        step={1}
        min={0}
        max={28}
        marks={marks}
        valueLabelDisplay="auto"
      />
      </div>
      </div>
      </Grid>


      <Grid item xs = {12}>
      <TextField
          id="standard-textarea"
          label="Style Sheet"
          value={props.style_sheet}
          size="small"
          placeholder="Add a Css style sheet"
          variant = "outlined"
          onChange= {props.handleStyleSheetChange}
          style = {{ width : '98%'}}
          size = "small"
          multiline 
          inputProps={{  style:{minHeight:'240px'} }}
        />
      </Grid>


      {/*<Grid item xs={12} >

        <div style={{width:'98%', minWidth: '200',marginTop:'0px'}}>

          <CKEditor 

          onChange={evt => {console.log(evt.editor.getData());props.set_ck_state(evt.editor.getData().toString())}}

          data= {props.ck_state}

          config = {{toolbar:[
            { name: 'document', items: [ 'Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates' ] },
            { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
            { name: 'editing', items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] },
            { name: 'forms', items: [ 'Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField' ] },
            '/',
            { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
            { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language' ] },
            { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
            { name: 'insert', items: [ 'Image', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe' ] },
            '/',
            { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
            { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
            { name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
          ]}}

          />

         </div>

        </Grid>*/}


      <Grid item xs={12} >
        <div style={{width:'98%', minWidth: '200',marginTop:'0px'}}>
          <CKEditor 
          onChange={evt => {console.log(evt.editor.getData());props.set_ck_state(evt.editor.getData().toString())}}
          data= {props.ck_state}
          config = {{extraPlugins:['stylesheetparser'],
          //contentsCss:props.style_sheet,
          allowedContent:true,
            toolbar:[
            { name: 'document', items: [ 'Source', '-', 'Save', 'NewPage', 'Preview', 'Print', '-', 'Templates' ] },
            { name: 'clipboard', items: [ 'Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-', 'Undo', 'Redo' ] },
            { name: 'editing', items: [ 'Find', 'Replace', '-', 'SelectAll', '-', 'Scayt' ] },
            { name: 'forms', items: [ 'Form', 'Checkbox', 'Radio', 'TextField', 'Textarea', 'Select', 'Button', 'ImageButton', 'HiddenField' ] },
            '/',
            { name: 'basicstyles', items: [ 'Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript', '-', 'CopyFormatting', 'RemoveFormat' ] },
            { name: 'paragraph', items: [ 'NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', '-', 'Blockquote', 'CreateDiv', '-', 'JustifyLeft', 'JustifyCenter', 'JustifyRight', 'JustifyBlock', '-', 'BidiLtr', 'BidiRtl', 'Language' ] },
            { name: 'links', items: [ 'Link', 'Unlink', 'Anchor' ] },
            { name: 'insert', items: [ 'Image', 'Table', 'HorizontalRule', 'Smiley', 'SpecialChar', 'PageBreak', 'Iframe' ] },
            '/',
            { name: 'styles', items: [ 'Styles', 'Format', 'Font', 'FontSize' ] },
            { name: 'colors', items: [ 'TextColor', 'BGColor' ] },
            { name: 'tools', items: [ 'Maximize', 'ShowBlocks' ] },
          ]}}
          />
         </div>

      </Grid>

      <Grid item xs = {12}>
      <OutlinedDiv label="Preview">
      <div> <style>  {props.style_sheet} </style> <div dangerouslySetInnerHTML={ { __html: props.ck_state} }></div></div>
      </OutlinedDiv>
      </Grid>


      {/*props.locations.length > 0 ? <Grid item xs={12} style = {{marginTop: '2px',marginLeft: '0% ', marginBottom:'4px'}*/ } 

      {/*<label style = {{marginBottom: '8px',"font-size":"18px" }}>URLs</label>*/}

      {/*props.locations.map((location, index) => {
        return  <CustomLocation style={{width:'98%'}} key = {location.id} url = {'https://' + location.store_domain} name = {location.country} color_value = {location.color} click_handler = { handleChange.bind(this, index, location) } ></CustomLocation>
      })*/}

      
      </Grid>

      <Grid item xs = {12}>

       <Button style = {{marginLeft: '1%', width : '98%', marginTop: '12px'}} variant="outlined" color="primary" onClick={handleOpen}>Preview</Button>
       
      </Grid>

      <Grid item xs = {12}>

      <Button style = {{marginLeft: '1%', width : '98%', marginTop: '12px'}} variant="outlined" color="primary" onClick={event=> props.handle_deploy("save")}>Save</Button>

      </Grid>

      <Grid item xs = {12}>

       <Button style = {{marginLeft: '1%', width : '98%', marginTop: '12px'}} variant="outlined" color="primary" onClick={event=> props.handle_deploy("deploy")}>Save and Deploy</Button>
       
      </Grid>


      </main>

    </div>



  );
}

export default ConfigureForm;



  /*
.map(function(o){
      // use reduce to make objects with only the required properties
      // and map to apply this to the filtered array as a whole
      return props.reduce(function(newo, name){
          newo[name] = o[name];
          return newo;
      }, {});
  });
  */