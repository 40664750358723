import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { withStyles, lighten, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import EnhancedTable from '../StoreShippingZones/StoreShippingZones'
import Themes from '../Themes/Themes'
import ConfigureForm from '../ConfigureForm/ConfigureForm'
import CircularProgress from '@material-ui/core/CircularProgress'
import PreviewModal from '../PreviewModal/PreviewModal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Backdrop from '@material-ui/core/Backdrop';

import Stores from '../AddStores/Stores'


const styles = (theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-end',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  table: {
    minWidth: 750,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px',
  },
  pBar:{
    marginTop: '100px',
    marginBottom: '100px',
    marginLeft: '48%',
    position:'relative',
  },
});

function ThemeContent(props) {

  const isMountedRef = useRef(null);

  const { classes } = props;

  const [isLoading, setIsLoading] = React.useState(false)

  const [themes, set_themes] = React.useState([])

  const [isStoreLoading, setIsStoreLoading] = React.useState(false)

  const [all_stores, set_stores] = React.useState([])

  const [selected_stores, setSelectedStores] = React.useState([])



  const reset_state = () =>{
    setSelectedStores([])
    set_stores([])
    setIsStoreLoading(false)
    setIsLoading(false)
    set_themes([])
  }


  const refresh_clicked = (event) => {
    if(!isStoreLoading && !isLoading){
    reset_state()
    get_all_stores(props.current_shop)
  }
  };




  useEffect(()=>{
    isMountedRef.current = true
    if(props.current_shop){
    get_all_stores(props.current_shop)
    }
   // get_all_themes(props.current_shop)
    return () => isMountedRef.current = false;
  },[])


  const handle_store_click = (store_domain) => {

    if(!isStoreLoading){
    setSelectedStores(store_domain)
    if(store_domain.length > 0){
    get_all_themes(props.current_shop, store_domain[0])
    }else{
      set_themes([])
    }
    }
  }
  




  return (
    <div>   
    <Paper className={classes.paper}>
      <AppBar style={{borderBottom: '1px solid rgba(0, 0, 0, 0.12)'}} position="static" color="default" elevation={0}>
        <Toolbar>
          
        <Grid container spacing={2} alignItems="center">
        
            <Grid item xs> 
              <TextField
                fullWidth
                placeholder= {""}
                disabled = {true}
                InputProps={{
                  disableUnderline: true,
                  className: classes.searchInput,
                }}
              />
            </Grid> 
            <Grid item>
              <Tooltip title="Reload">
                <IconButton onClick = {refresh_clicked.bind(this)}>
                  <RefreshIcon className={classes.block} color="inherit" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>


    <div className={classes.contentWrapper}>

    {isStoreLoading == true ? <CircularProgress className = {classes.pBar} /> : <Stores handle_store_click = {handle_store_click} selected = {selected_stores} rows = {all_stores}></Stores> }

      {isLoading == true ? <CircularProgress className = {classes.pBar} /> : selected_stores.length > 0 ? 
      <div>
         <hr style={{marginLeft:'2%',marginRight:'2%',marginBottom:'2%'}}></hr>     
      <Typography style={{marginLeft:'12px'}} className={classes.title} variant="h6" id="tableTitle" component="div">
          Themes
        </Typography>
        
        <Table className={classes.table} aria-label="simple table">
      
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
              {/* <TableCell align="right">Name</TableCell> */}
            <TableCell align="right">Updated at</TableCell>
            <TableCell align="right">Previewable</TableCell>
            <TableCell align="right">Role</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {themes.map((row) => (
            <TableRow key={row.id}>
              <TableCell component="th" scope="row">
                {row.name}
              </TableCell>
              {/*<TableCell align="right">{row.name}</TableCell>*/}
                <TableCell align="right">{row.updated_at}</TableCell>
                <TableCell align="right">{row.previewable.toString()}</TableCell>
                <TableCell align="right">{row.role}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
      </div>: null}

      </div>
    </Paper>
    </div>
  );



  function get_all_stores(current_shop) {
  
    setIsStoreLoading(true)
  
    fetch('https://gt0425yc42.execute-api.eu-west-2.amazonaws.com/first/connected-stores'+ current_shop.search, {
        crossDomain:true,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'store_domain': current_shop.shop
        }
      }).then(res => res.json())
        .then(
          (result) => {
  
            console.log('stores were',result)

            if(isMountedRef.current){
  
              setIsStoreLoading(false)
  
              if(result.connected_stores){
  
                set_stores(result.connected_stores)
  
            }else{
  
              props.handle_snack('error','Error fetching stores!',true)
  
            }
  
            }
    
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            if(isMountedRef.current){
              props.handle_snack('error','Error fetching stores!',true)
              setIsStoreLoading(false)
            }
            console.log(error)
            //set_shipping_error(true)
          }
    )
  
  }


  function get_all_themes(current_shop,store_domain) {

    console.log('called Themes')
  
    setIsLoading(true)
  
    fetch('https://gt0425yc42.execute-api.eu-west-2.amazonaws.com/first/themes' + current_shop.search,{
        crossDomain:true,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'store_domain': store_domain
        }
      }).then(res => res.json())
        .then(
          (result) => {

            if(isMountedRef.current){

            setIsLoading(false)

            console.log(result)

            
            if(result && result.themes_data && result.themes_data.themes){                
              set_themes(result.themes_data.themes)
            }else{
              props.handle_snack('error','Error fetching themes!',true)
            }
          }
            
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            if(isMountedRef.current){
             props.handle_snack('error','Error fetching themes!',true)
            setIsLoading(false)
            console.log(error)
            }
            //set_shipping_error(true)
          }
    )
  
  }
}


ThemeContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ThemeContent);


// API Gateway, get all stores with current email

// Get all combined shipping zones

// display shipping zones with store name
