

import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { withStyles, lighten, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import EnhancedTable from '../StoreShippingZones/StoreShippingZones'
import Themes from '../Themes/Themes'
import ConfigureForm from '../ConfigureForm/ConfigureForm'
import CircularProgress from '@material-ui/core/CircularProgress'
import PreviewModal from '../PreviewModal/PreviewModal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Collapse from '@material-ui/core/Collapse';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Box from '@material-ui/core/Box';

import Backdrop from '@material-ui/core/Backdrop';


const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },

});



const styles = (theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-end',
  },
  table: {
    minWidth: 750,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px',
  },
  pBar:{
    marginTop: '100px',
    marginBottom: '100px',
    marginLeft: '48%',
    position:'relative',
  },
});

function ShippingContent(props) {

  const isMountedRef = useRef(null);

  const { classes } = props;

  const [isLoading, setIsLoading] = React.useState(false)

  const [shipping_zones, set_shipping_zones] = React.useState([])






  const reset_state = () =>{
    setIsLoading(false)
    set_shipping_zones([])
  }


  const refresh_clicked = (event) => {
      get_all_shipping_zones(props.current_shop)
  };




  useEffect(()=>{
    isMountedRef.current = true
    if(props.current_shop){
    get_all_shipping_zones(props.current_shop)
    }
    return () => isMountedRef.current = false;
  },[])


  function Row(props) {

    const { row } = props;
    const index = props.index
    const [open, setOpen] = React.useState(false);
    const classes = useRowStyles();
  
    return (
      <React.Fragment>
        <TableRow className={classes.root}>
          <TableCell style={{paddingRight:'0'}}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
          <TableCell >
        </TableCell>
        
          <TableCell component="th" scope="row">
          {row.store_name}
          </TableCell>
    { /*<TableCell align="right">{row.store_name}</TableCell>*/ }
          <TableCell align="right">{row.store_domain}</TableCell>
          <TableCell align="right">{row.store_email}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box margin={1}>
                <Typography variant="h6" gutterBottom component="div">
                  Shipping Zones
                </Typography>
                <Table size="small" aria-label="purchases">
                  <TableHead>
                    <TableRow>
                      <TableCell>Name</TableCell>
    { /*<TableCell align="right">Name</TableCell> */}
                      <TableCell align="right">Country</TableCell>
                      <TableCell align="right">Code</TableCell>
                      <TableCell align="right">Tax Name</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.shipping_zones.map((historyRow,index2) => (
                      <TableRow  tabIndex={-1} role="checkbox" hover key={historyRow.id}>

                     
                        <TableCell component="th" scope="row">
                          {historyRow.name}
                        </TableCell>
                    { /*<TableCell align="right">{historyRow.name}</TableCell> */}
                        <TableCell align="right">{historyRow.country}</TableCell>
                        <TableCell align="right">
                          {historyRow.code}
                        </TableCell>
                        <TableCell align="right">
                          {historyRow.tax_name}
                        </TableCell>
                      </TableRow>

                    ))}
                  </TableBody>
                </Table>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </React.Fragment>
    );
}


  return (
    <div>   
    <Paper className={classes.paper}>
      <AppBar style={{borderBottom: '1px solid rgba(0, 0, 0, 0.12)'}} position="static" color="default" elevation={0}>
        <Toolbar>
        <Grid container spacing={2} alignItems="center">
            <Grid item xs> 
              <TextField
                fullWidth
                placeholder= {""}
                disabled={true}
                InputProps={{
                  disableUnderline: true,
                  className: classes.searchInput,
                }}
              />
            </Grid> 
            <Grid item>
              <Tooltip title="Reload">
                <IconButton onClick = {refresh_clicked.bind(this)}>
                  <RefreshIcon className={classes.block} color="inherit" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>


      <div className={classes.contentWrapper}>
      {isLoading == true ? <CircularProgress className = {classes.pBar} /> :  <div className={classes.root}>
    <TableContainer>
      <Table className={classes.table}  aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell style={{paddingRight:'0'}}/>
            <TableCell style={{paddingRight:'0'}}/>
            <TableCell>Store Domain</TableCell>
              {/* <TableCell align="right">Store Name</TableCell> */}
            <TableCell align="right">Store Domain</TableCell>
            <TableCell align="right">Email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {shipping_zones.map((row,index) => (
            <Row index = {index}  key={row.store_id} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
    </div>}

      </div>
    </Paper>
    </div>
  );


  function get_all_shipping_zones(current_shop) {
    console.log('called shipping')

    setIsLoading(true)
  
    fetch('https://gt0425yc42.execute-api.eu-west-2.amazonaws.com/first/shipping-zones'+ current_shop.search,{
        crossDomain:true,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'store_domain': current_shop.shop
        }
      })
        .then(res => res.json())
        .then(
          (result) => {
            if(isMountedRef.current){
            setIsLoading(false)
            console.log(result)
            if(result.shipping_zones){                

              set_shipping_zones(result.shipping_zones)
            }else{
              props.handle_snack('error','Error fetching shipping zones!',true)
                //display error
            }
            }
            
            
            
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            if(isMountedRef.current){
              props.handle_snack('error','Error fetching shipping zones!',true)
            setIsLoading(false)
            }
            console.log(error)
          }
    )
  
  }
}

ShippingContent.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ShippingContent);


// API Gateway, get all stores with current email

// Get all combined shipping zones

// display shipping zones with store name




