
//import ReactDOM from 'react-dom';
import CustomLocation from '../CustomLocation/CustomLocation';

import PreviewModal from '../PreviewModal/PreviewModal';

import countries from '../../datasets/countries'

import React, { useReducer, useEffect, useCallback, useMemo } from 'react';


import MenuIcon from '@material-ui/icons/Menu';

//import { Container } from '@material-ui/core';

import { Grid, CssBaseline, List, Drawer, Modal, Paper, FormControl, Input, InputLabel, FormHelperText, Select, MenuItem, AppBar,  makeStyles, useTheme, Chip, TextField , Toolbar, Typography, Button, IconButton} from '@material-ui/core';

import clsx from 'clsx';
import Divider from '@material-ui/core/Divider';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import MailIcon from '@material-ui/icons/Mail';
import AddIcon from '@material-ui/icons/Add'
import VisibilityIcon from '@material-ui/icons/Visibility';
import FilterListIcon from '@material-ui/icons/FilterList';
import DraftsIcon from '@material-ui/icons/Drafts';
import FeedbackIcon from '@material-ui/icons/Feedback';

const drawerWidth = 240;

//<AppBar position="static" className = {classes.appbar_background}>

const useStyles = makeStyles((theme) => ({


  appbar_background: {
    background : '#20c2e8',
  },
  main_logo: {
    width: '120px', height: '30px', marginBottom : '2px',
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    marginLeft: '2px',
    marginTop: '9px',
   'font-family': '\'Fira Sans\', sans-serif',
   'font-weight' : '500',
   'font-size': '28px',
  },
  main_button:{
    'font-family': '\'Fira Sans\', sans-serif',
    'font-weight' : 'bold',
    'paddingBottom': '0px',
     'marginTop': '8px',
    'font-size': '26px',
    'text-transform': 'none',
  },
  formControl: {
    minWidth: 120,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  paper: {
    padding: theme.spacing(2),
    margin: 'auto',
    marginTop: '12px',
    width: '50%',
  },
  appBar: {
    background : '#20c2e8',
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  hide: {
    display: 'none',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;

const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};


function getStyles(name, country_names, theme) {

  return {
    fontWeight:
    country_names.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };

}



function handleChangeBinded(index, name, e){
 
  console.log(index)

  console.log(name)

  console.log(e.target.value)

}

 
function App() {

  const theme = useTheme();

  const classes = useStyles();

  const [location, setLocation] = React.useState({ "locations": [], "colored_locations":[]});

  const [open, setOpen] = React.useState(false);

  const [form_title, set_form_title] = React.useState('');

  const [form_body, set_form_body] = React.useState('');

  const [redirect_urls, set_redirect_urls] = React.useState([]);

  const [drawerOpen, setDrawerOpen] = React.useState(false);

  const handleDrawerOpen = () => {
    setDrawerOpen(true);
  };

  const handleDrawerClose = () => {
    setDrawerOpen(false);
  };

  //const [colored_locations, setcolLocations] = React.useState([]);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    console.log('using effect')
  });

  console.log('colored_locations',location)


  const set_urls = (index, event) => {

    const previous_urls = [...redirect_urls]

    console.log(index);

    console.log(event.target.value)

    if(index > previous_urls.length - 1)
    {
      previous_urls.push(event.target.value)
    }else{
      previous_urls[index] = event.target.value
    }
    console.log(previous_urls)

    set_redirect_urls(previous_urls)

  }

  const handleChange = (index, name, event) => {

    console.log(index)

    console.log(name)
  
    console.log(event.target.value)
   
    if(index > -1){

      const prev_locations = [...location.locations]
      const previous_colored_locations = [...location.colored_locations]
      previous_colored_locations[index].color = event.target.value
      const new_obj = {"locations": prev_locations,"colored_locations": previous_colored_locations}
      setLocation(new_obj)

    }else{

      const new_locations = event.target.value

      const previous_colored_locations = [...location.colored_locations]

      const colored_locations = []

       new_locations.forEach((element) => {
        colored_locations.push({'name':element,'color': '#FFFFFF'})
      });

      previous_colored_locations.forEach((element)=>{
        colored_locations.forEach((element1)=>{
          if(element.name == element1.name){
            element1.color = element.color
          }
        });
      });

      const new_obj = {"locations": new_locations,"colored_locations": colored_locations}
      setLocation(new_obj)
    }


    //prev_locations.push(event.target.value)

/*
    const colored_locations = []

    prev_locations.forEach((element) => {
      colored_locations.push({'name':element,'color': event.target.value})
    });

    const new_obj = {"locations": prev_locations,"colored_locations": colored_locations}

    console.log('event....',event.target.value)

    setLocation(new_obj);
    //console.log('target',event.target.value)
    //setcolLocations(result => [...result, { 'name' : event.target.value[event.target.value.length - 1], "color": "#FFFFFF"}])
    //console.log('colored_locations',ocation)
    console.log(location)
    */
  };


/*
  const handleChangeMultiple = (event) => {
    const { options } = event.target;
    const value = [];
    for (let i = 0, l = options.length; i < l; i += 1) {
      if (options[i].selected) {
        value.push(options[i].value);
      }
    }
    setLocation(value);
  };
*/

  return (

    <div className={classes.root}>

      <AppBar
        position="fixed"
        className={clsx(classes.appBar, {
          [classes.appBarShift]: drawerOpen,
        })}
      >

        <Toolbar>

        <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            className={clsx(classes.menuButton, {
              [classes.hide]: drawerOpen,
            })}
          >
            <MenuIcon />
          </IconButton>
      <img src={require('../../images/Datasaur-Logo.jpg')} className = {classes.main_logo}></img>

          <Typography variant="h4" className={classes.title}>
            GeoLocation
          </Typography>

        </Toolbar>

      </AppBar>

      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: drawerOpen,
          [classes.drawerClose]: !drawerOpen,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: drawerOpen,
            [classes.drawerClose]: !drawerOpen,
          }),
        }}
      >
        <div className={classes.toolbar} style = {{backgroundColor:'#20c2e8'}}>

          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon style={{ color: 'white' }}></ChevronRightIcon> : <ChevronLeftIcon style={{ color: 'white' }}></ChevronLeftIcon>}
          </IconButton>
        </div>
        <Divider />
        <List>
        <ListItem button>

         <ListItemIcon>{<AddIcon></AddIcon>}</ListItemIcon>
            <ListItemText primary={'New Section'} />
          </ListItem>

          <ListItem button>

          <ListItemIcon>{<VisibilityIcon></VisibilityIcon>}</ListItemIcon>
            <ListItemText primary={'All Sections'} />
          </ListItem>

          <ListItem button>
          <ListItemIcon>{<FilterListIcon></FilterListIcon>}</ListItemIcon>
            <ListItemText primary={'List Themes'} />
          </ListItem>

          <ListItem button>
          <ListItemIcon>{<DraftsIcon></DraftsIcon>}</ListItemIcon>
            <ListItemText primary={'Drafts'} />
          </ListItem>

        </List>

        <Divider />

        <List>
        <ListItem button>
          <ListItemIcon>{<FeedbackIcon></FeedbackIcon>}</ListItemIcon>
            <ListItemText primary={'Give Feedback'} />
          </ListItem>
        </List>
      </Drawer>


      <main className={classes.content}>

      <div className={classes.toolbar} />

     <Paper className={classes.paper} elevation={5}>

      <Grid container direction="row" spacing={2} justify="flex-start" alignItems="center" style = {{margin:'auto',padding: '20px'}}>

      <Grid item xs={12}>

      <FormControl className={classes.formControl}>

      <InputLabel htmlFor="section_name">Section Name</InputLabel>

      <Input id="section_name" aria-describedby="section-helper-text" />

     <FormHelperText id="section-helper-text">This will be saved as an Asset in your Theme File</FormHelperText>

     </FormControl>

     </Grid>

      <Grid item xs={12}>

      <FormControl className={classes.formControl}>

      <InputLabel htmlFor="popup_heading">Popup Heading</InputLabel>

      <Input id="popup_heading" aria-describedby="popup_heading_helper_text" onChange={event => set_form_title(event.target.value)} />

      <FormHelperText id="popup_heading_helper_text">Describe the title of your Popup</FormHelperText>

      </FormControl>

      </Grid>

      <Grid item xs={12}>

      <FormControl className={classes.formControl}>

      <InputLabel htmlFor="popup_description">Popup Description</InputLabel>

      <Input id="popup_description" aria-describedby="popup_description_helper_text" onChange={event => set_form_body(event.target.value)}/>

      <FormHelperText id="popup_description_helper_text">Describe the actions of your Popup</FormHelperText>

      </FormControl>

      </Grid>

     <Grid item xs={12}>

     <FormControl className={classes.formControl}>

        <InputLabel id="demo-mutiple-chip-label" >Locations</InputLabel>

        <Select
          labelId="demo-mutiple-chip-label"
          id="demo-mutiple-chip"
          aria-describedby="locations-helper-text"
          multiple
          value={location.locations}
          onChange={ handleChange.bind(this, -1, 'None') }
          input={<Input id="select-multiple-chip" />}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                <Chip key={value} label={value} className={classes.chip} />
              ))}
            </div>
          )}
          MenuProps={MenuProps}
        >
          {countries.map((name) => (

            <MenuItem key={name.name} value={name.name} style={getStyles(name.name, location.locations, theme)}>
              {name.name}
            </MenuItem>

          ))}

        </Select>

      <FormHelperText id="locations-helper-text">Select countries for redirect urls</FormHelperText>

      </FormControl>

      </Grid>

       <div>

      <Grid item xs={12} style = {{marginLeft: '8px', marginTop: '4px'}}>

      { location.colored_locations.length > 0 ? <label>Add URLs</label> :  <label></label>}

      {location.colored_locations.map((name, index) => {
        return  <CustomLocation key = {name.name.toString()} url_added = {set_urls.bind(this, index)} name = {name.name} color_value = {name.color} click_handler = { handleChange.bind(this, index, name) } ></CustomLocation>
      })}

      </Grid>

      </div>
      
      </Grid>

      <Grid item xs = {12}>

       <Button style = {{marginLeft: '2%', width : '96%'}} variant="outlined" color="primary" onClick={handleOpen}>Preview</Button>
       
      </Grid>

      </Paper>

      <PreviewModal open = {open} redirect_to = {redirect_urls} handleClose = {handleClose} model_heading={form_title} model_text = {form_body} selected_locations = {location.colored_locations}></PreviewModal>

      </main>

    </div>



  );
}

export default App;



/*


     <FormControl className={classes.formControl}>
        <InputLabel id="demo-simple-select-helper-label">Locations</InputLabel>
        <Select
          labelId="demo-simple-select-helper-label"
          id="demo-simple-select-helper"
          value={location}
          onChange={handleChange}
        >
          <MenuItem value="">
            <em>None</em>
          </MenuItem>
          <MenuItem value={10}>Ten</MenuItem>
          <MenuItem value={20}>Twenty</MenuItem>
          <MenuItem value={30}>Thirty</MenuItem>
        </Select>
        <FormHelperText>Some important helper text</FormHelperText>
      </FormControl>

*/

// callbacks

// rerendering

// infinite loops

// optimization

/*
<IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
            <MenuIcon />
          </IconButton>

*/