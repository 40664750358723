import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { withStyles, lighten, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import EnhancedTable from '../StoreShippingZones/StoreShippingZones'
import Themes from '../Themes/Themes'
import ConfigureForm from '../ConfigureForm/ConfigureForm'
import CircularProgress from '@material-ui/core/CircularProgress'
import PreviewModal from '../PreviewModal/PreviewModal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

import Backdrop from '@material-ui/core/Backdrop';




const styles = (theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-end',
  },
  table: {
    minWidth: 750,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px',
  },
  pBar:{
    marginTop: '100px',
    marginBottom: '100px',
    marginLeft: '48%',
    position:'relative',
  },
});

function Settings(props) {


  const { classes } = props;

  const [isLoading, setIsLoading] = React.useState(false)

  const [settings, set_settings] = React.useState([])






  const reset_state = () =>{
    setIsLoading(false)
    set_settings([])
  }


  const refresh_clicked = (event) => {
      get_all_settings(props.current_shop)
  };




  useEffect(()=>{
 
  },[])

  return (
    <div></div>
  );


  function get_all_settings(current_shop) {

    console.log('called Settings')
  
    setIsLoading(true)
  
    fetch('https://gt0425yc42.execute-api.eu-west-2.amazonaws.com/first/settings'+current_shop.search,{
        crossDomain:true,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'store_domain': current_shop.shop
        }
      })
        .then(res => res.json())
        .then(
          (result) => {

            setIsLoading(false)

            console.log(result)
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            setIsLoading(false)
            console.log(error)
            //set_shipping_error(true)
          }
    )
  
  }
}


Settings.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Settings);


// API Gateway, get all stores with current email

// Get all combined shipping zones

// display shipping zones with store name
