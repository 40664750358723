import React, {useEffect, useState, useRef} from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles ,withStyles} from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';
import AppBar from '@material-ui/core/AppBar';
import Grid from '@material-ui/core/Grid';
import RefreshIcon from '@material-ui/icons/Refresh';
import TextField from '@material-ui/core/TextField';
import SearchIcon from '@material-ui/icons/Search';
import CircularProgress from '@material-ui/core/CircularProgress'
import EditIcon from '@material-ui/icons/Edit';
import ConfigureForm from '../ManageConfigure/ConfigureForm'
import countries from '../../datasets/countries'
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import PreviewModal from '../PreviewModal/PreviewModal';
import Container from '@material-ui/core/Container';
import Stores from '../AddStores/Stores'
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import { Chip} from '@material-ui/core';
import BarChart from '../BarChat/BarChart'
import MapChart from "../MapChart/MapChart"
import ReactTooltip from "react-tooltip";

function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }
  
  const headCells = [
    { id: 'id', numeric: false, disablePadding: true, label: 'Name' },
    { id: 'theme_name', numeric: true, disablePadding: false, label: 'Theme' },
    { id: 'created_at', numeric: true, disablePadding: false, label: 'Created on' },
    { id: 'updated_at', numeric: true, disablePadding: false, label: 'Updated on' },
    { id: 'is_live', numeric: true, disablePadding: false, label: 'Live' },
  ];


  function EnhancedTableHead(props) {

    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
  
    const createSortHandler = (property) => (event) => {
      onRequestSort(event, property);
    };
  
    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox">
            {/*
            <Checkbox
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all desserts' }}
            />
            */ }
          </TableCell>
          {headCells.map((headCell) => (
            <TableCell
              key={headCell.id}
              align={headCell.numeric ? 'right' : 'left'}
              padding={headCell.disablePadding ? 'none' : 'default'}
              sortDirection={orderBy === headCell.id ? order : false}
            >
              <TableSortLabel
                active={orderBy === headCell.id}
                direction={orderBy === headCell.id ? order : 'asc'}
                onClick={createSortHandler(headCell.id)}
              >
                {headCell.label}
                {orderBy === headCell.id ? (
                  <span className={classes.visuallyHidden}>
                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                  </span>
                ) : null}
              </TableSortLabel>
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
    );
  }
  
  EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(['asc', 'desc']).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
  };
  
  const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    highlight:
      theme.palette.type === 'light'
        ? {
            color: theme.palette.secondary.main,
            backgroundColor: lighten(theme.palette.secondary.light, 0.85),
          }
        : {
            color: theme.palette.text.primary,
            backgroundColor: theme.palette.secondary.dark,
          },
    title: {
      flex: '1 1 100%',
    },
  }));

  const EnhancedTableToolbar = (props) => {

    const classes = useToolbarStyles();
  
    const { numSelected } = props;
  
  
    return (
      <Toolbar
        className={clsx(classes.root, {
          [classes.highlight]: numSelected > 0,
        })}
      >
        {numSelected > 0 ? (
          <Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
            {numSelected} selected
          </Typography>
        ) : (
          <Typography className={classes.title} variant="h6" id="tableTitle" component="div">{props.type}</Typography>
        )}
  
        {numSelected > 0 ? (
          <div style= {{display:'flex'}}>
              <Tooltip title="Delete">
            <IconButton onClick = {props.handleDel} aria-label="delete">
              <DeleteIcon />
            </IconButton>
          </Tooltip>
  
        <Tooltip title="Edit">
          <IconButton onClick = {props.handleEdit} aria-label="Edit">
            <EditIcon />
          </IconButton>
        </Tooltip>
  
          </div>
        
        ) : (
          <Tooltip title="Filter list">
            <IconButton aria-label="filter list">
              <FilterListIcon />
            </IconButton>
          </Tooltip>
        )}
      </Toolbar>
    );
    
  };
  
  EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
  };
  


const styles = (theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-end',
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  cardGrid: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  table: {
    minWidth: 750,
  },
  cardContent: {
    flexGrow: 1,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px',
  },
  pBar:{
    marginTop: '100px',
    marginBottom: '100px',
    marginLeft: '48%',
    position:'relative',
  },
  MuiTypography: {
    color: "black",
    fontSize: "14px",
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    fontWeight: 'bold',
    lineHeight: '1.6',
  },
  MuiTypographyh2 :{
    fontSize: '2.125rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    fontWeight: 400,
    lineHeight: 1.17,
    letterSpacing: '0.00735em',
  },
  MuiTypographyh3 : {
    fontSize: '1.0rem',
    fontFamily: '"Roboto", "Helvetica", "Arial", "sans-serif"',
    fontWeight: 400,
    lineHeight: 1.0,
    letterSpacing: '0em',
  },
  MuiTypographyh4 :{
    fontSize: '20px',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontWeight: 500,
    lineHeight: 1.17,
    letterSpacing: '0.00735em',
  },
  MuiTypographysub1 :{
    color:'#4caf50',
    fontSize: '12px',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontWeight: 500,
    lineHeight: 1.17,
    letterSpacing: '0.00735em',
  },
  MuiTypographysub2 :{
    color:'#737373',
    fontSize: '8px',
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    fontWeight: 500,
    lineHeight: 1.17,
    letterSpacing: '0.00735em',
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },contentWrapper: {
    margin: '16px',
  },
});


function Analytics(props) {

  const isMountedRef = useRef(null);

  const { classes } = props;

  const [isLoading, setIsLoading] = React.useState(false)

  const [isAnalyticsLoading, setAnalyticsIsLoading] = React.useState(false)

  const [rows, set_sections] = React.useState([])

  const [isStoreLoading, setIsStoreLoading] = React.useState(false)

  const [all_stores, set_stores] = React.useState([])

  const [content, setContent] = React.useState("");

  const [selected_stores, setSelectedStores] = React.useState([])

  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  
  const [page, setPage] = React.useState(0);

  const [selected, setSelected] = React.useState([])

  const [analytics_data, set_analytics_data] = React.useState({})


  const reset_state = () =>{
    set_analytics_data({})
    setAnalyticsIsLoading(false)
    setContent("")
    setSelectedStores([])
    set_stores([])
    setIsStoreLoading(false)
    setIsLoading(false)
    set_sections([])
  }


  const refresh_clicked = (event) => {
    if(!isStoreLoading && !isLoading){
    reset_state()
    get_all_stores(props.current_shop)
  }
  };

  const handleSectionOpen = (dt) => {
    console.log('handling open',dt)

    get_analytics_details(props.current_shop,dt.section_name,selected_stores[0]) 
  }


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };



  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);



  useEffect(()=>{
    isMountedRef.current = true
    if(props.current_shop){
    get_all_stores(props.current_shop)
    }
   // get_all_themes(props.current_shop)
    return () => isMountedRef.current = false;
  },[])




  useEffect(()=>{
  console.log('at was', analytics_data)
  },[analytics_data])

  const handle_store_click = (store_domain) => {

    if(!isStoreLoading){
    console.log('sel store was', store_domain)
    setSelectedStores(store_domain)
    if(store_domain.length > 0){
    get_all_sections(props.current_shop, store_domain[0])
    }else{
      set_analytics_data([])
      set_sections([])
    }
    }
  }
  




  return (
    <div>   
    <Paper className={classes.paper}>
      <AppBar style={{borderBottom: '1px solid rgba(0, 0, 0, 0.12)'}} position="static" color="default" elevation={0}>
        <Toolbar>
          
        <Grid container spacing={2} alignItems="center">
        
            <Grid item xs> 
              <TextField
                fullWidth
                placeholder= {""}
                disabled = {true}
                InputProps={{
                  disableUnderline: true,
                  className: classes.searchInput,
                }}
              />
            </Grid> 
            <Grid item>
              <Tooltip title="Reload">
                <IconButton onClick = {refresh_clicked.bind(this)}>
                  <RefreshIcon className={classes.block} color="inherit" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>


      <div className={classes.contentWrapper}>

    {isStoreLoading == true ? <CircularProgress className = {classes.pBar} /> : <Stores handle_store_click = {handle_store_click} selected = {selected_stores} rows = {all_stores}></Stores> }

    {isLoading == true ? <CircularProgress className = {classes.pBar} /> : (rows.length > 0 &&  selected_stores.length > 0) ?

                    <div>
                  <hr style={{marginLeft:'2%',marginRight:'2%',marginBottom:'1%'}}></hr>     
                    <EnhancedTableToolbar type = {"Sections"}  numSelected={selected.length} />

                    <Container className={classes.cardGrid} maxWidth="md">

                    <Grid container spacing={4}>

                        {rows.map((card,index) => (
                        <Grid item key={card.id} xs={12} sm={6} md={4}>
                            
                            <Card variant="elevation" elevation={6} className={classes.card}>
                            { /*<CardMedia
                                className={classes.cardMedia}
                                image={card.url}
                                title="Image title"
                                <div style={{width:'100%',height:'50%',overflow: 'hidden', border:'1px solid black'}} dangerouslySetInnerHTML={ { __html: "<div style = 'height:100%;width:100%; position: relative; top: 50%; left: 50%;transform: translateX(-50%) translateY(-50%);'>" +card.template_body +"</div>"} }></div>   

                            />*/}
                       <Paper square = {true} variant='elevation' elevation={2} style={{borderBottomLeftRadius:'120px 40px',borderBottomRightRadius:'120px 40px',overflow: 'hidden',backgroundColor: '#f8f7fc'}}><iframe  style={{width:'100%',height:'200px',overflow: 'hidden', border: '1px solid #f8f7fc','borderRadius': '10px 10px 10px 10px', 'WebkitTransform' : 'scale(0.75,0.6)','MozTransformScale(0.75,0.6)':'','objectFit': 'fill'}} srcDoc={"<html><head><style type='text/css'>"+ card.style_sheet + "</style></head><body>"+"<div style='object-fit: fill'>"+card.editor_data+"</div>"+"</body>"} ></iframe></Paper>

                    <CardContent className={classes.cardContent}>
                        <Typography style = {{fontWeight: 'bold'}}variant="h6" component="h2">
                        {card.section_name}
                        </Typography>
                        <Typography variant = "body2">
                        {card.themes ? card.themes.map(obj=> obj.name).toString() : ""}
                        </Typography>
                    </CardContent>
                    <div style={{marginTop: '6px', marginBottom: '6px',display: 'flex'}}><Button size="small" color="primary" onClick={(event) => handleSectionOpen(card)}>
                        View
                        </Button>
                    </div>
                    {/*<CardActions style={{marginTop: '6px'}}>
                        
                    </CardActions>*/}
                    </Card>
                </Grid>
                ))}
            </Grid>

            </Container>


</div> : null}



{isAnalyticsLoading == true ? <CircularProgress className = {classes.pBar} /> : (('total_instances_today' in analytics_data) && selected_stores.length > 0) ?   <div style={{margin:'12px'}}>
<hr style={{marginLeft:'2%',marginRight:'2%',marginBottom:'1%'}}></hr>     
<EnhancedTableToolbar type = {"Analytics"} numSelected={selected.length} />

     <div style={{marginTop:'12px'}}>

      <Grid className = {classes.root} container spacing = {1}>

        <Grid item xs= {4}>

        <Grid
          container
          spacing = {1}
          direction="row"
          justify="space-between"
          alignItems="stretch"
        >

        <Grid item  xs={6}>

<Paper  style={{paddingTop:'8px',paddingBottom:'8px', height: '120px'}}>

<div style={{marginLeft:'16px', marginRight:'12px'}}>

<Grid
container
direction="row"
justify="space-between"
alignItems="stretch"
>
<Typography className={classes.MuiTypography} variant="h6"> Visitors</Typography> 

<Chip style={{ fontSize: '7px' , marginTop: '0px',paddingLeft:'4px', paddingRight:'4px', marginLeft:'8px'}} size="small" color="primary" label="Today"/> 

</Grid>


<Typography style={{marginTop:'18px'}} className={classes.MuiTypographyh3} variant="h3"> {analytics_data.total_instances_today ? analytics_data.total_instances_today : 0}</Typography>

<div style={{display:'flex', marginTop:'18px'}}> <Typography  className={classes.MuiTypographysub1} variant="h6"> {analytics_data.total_instances_percent_today}</Typography><Typography style = {{marginLeft:'12px',marginTop:'4px'}} className={classes.MuiTypographysub2} variant="h6"> Since Last week</Typography></div> 

</div>

</Paper>

</Grid>


<Grid item  xs={6}>

<Paper  style={{paddingTop:'8px',paddingBottom:'8px', height: '120px'}}>

<div style={{marginLeft:'16px', marginRight:'12px'}}>

<Grid
container
direction="row"
justify="space-between"
alignItems="stretch"
>
<Typography className={classes.MuiTypography} variant="h6"> Popups</Typography> 

<Chip style={{ fontSize: '7px' , marginTop: '0px',paddingLeft:'4px', paddingRight:'4px', marginLeft:'8px'}} size="small" color="primary" label="Today"/> 

</Grid>


<Typography style={{marginTop:'18px'}} className={classes.MuiTypographyh3} variant="h3"> {analytics_data.total_popups_today ? analytics_data.total_popups_today : 0}</Typography>

                  <div style={{display:'flex', marginTop:'18px'}}> <Typography  className={classes.MuiTypographysub1} variant="h6"> {analytics_data.total_popups_percent_today}</Typography><Typography style = {{marginLeft:'12px',marginTop:'4px'}} className={classes.MuiTypographysub2} variant="h6"> Since Last week</Typography></div> 

</div>

</Paper>

</Grid>


<Grid item  xs={6}>

<Paper  style={{paddingTop:'8px',paddingBottom:'8px', height: '120px'}}>

<div style={{marginLeft:'16px', marginRight:'12px'}}>

<Grid
container
direction="row"
justify="space-between"
alignItems="stretch"
>
<Typography className={classes.MuiTypography} variant="h6"> Clicks</Typography> 

<Chip style={{ fontSize: '7px' , marginTop: '0px',paddingLeft:'4px', paddingRight:'4px', marginLeft:'8px'}} size="small" color="primary" label="Today"/> 

</Grid>


<Typography style={{marginTop:'18px'}} className={classes.MuiTypographyh3} variant="h3"> {analytics_data.total_clicks_today ? analytics_data.total_clicks_today : 0}</Typography>

                  <div style={{display:'flex', marginTop:'18px'}}> <Typography  className={classes.MuiTypographysub1} variant="h6"> {analytics_data.total_clicks_percent_today}</Typography><Typography style = {{marginLeft:'12px',marginTop:'4px'}} className={classes.MuiTypographysub2} variant="h6"> Since Last week</Typography></div> 

</div>

</Paper>

</Grid>


<Grid item  xs={6}>

<Paper  style={{paddingTop:'8px',paddingBottom:'8px', height: '120px'}}>

<div style={{marginLeft:'16px', marginRight:'12px'}}>

<Grid
container
direction="row"
justify="space-between"
alignItems="stretch"
>
<Typography className={classes.MuiTypography} variant="h6"> Converts </Typography> 

<Chip style={{ fontSize: '7px' , marginTop: '0px',paddingLeft:'4px', paddingRight:'4px'}} size="small" color="primary" label="Today"/> 

</Grid>


<Typography style={{marginTop:'18px'}} className={classes.MuiTypographyh3} variant="h3"> {analytics_data.conversion_rate_today ? analytics_data.conversion_rate_today : 0}%</Typography>

 <div style={{display:'flex', marginTop:'18px'}}> <Typography  className={classes.MuiTypographysub1} variant="h6"> {analytics_data.conversion_rate_percent_today}</Typography><Typography style = {{marginLeft:'12px',marginTop:'4px'}} className={classes.MuiTypographysub2} variant="h6"> Since Last week</Typography></div> 

</div>

</Paper>

</Grid>



    </Grid>

        </Grid>




        <Grid item xs={8}>

                <Paper style={{height: '248px'}}>

                <div>
                    <BarChart yearly_analytics = {analytics_data.yearly_analytics ? analytics_data.yearly_analytics : []}></BarChart>
                </div>

                </Paper>

        </Grid>


        
     </Grid>

     

    <Grid  className = {classes.root} container spacing = {2}>

        <Grid item xs={12}>

        <Paper >

        <div style={{marginTop:'8px'}}>

        <MapChart setTooltipContent={setContent} country_level_analytics = { analytics_data.country_level_analytics ? analytics_data.country_level_analytics : [] } />

        <ReactTooltip>{content}</ReactTooltip>

        </div>

        </Paper>

        </Grid>

        
     </Grid>
     </div>
      </div> : null }

      </div>
    </Paper>
    </div>
  );



  function get_all_stores(current_shop) {
  
    setIsStoreLoading(true)
  
    fetch('https://gt0425yc42.execute-api.eu-west-2.amazonaws.com/first/connected-stores'+ current_shop.search, {
        crossDomain:true,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'store_domain': current_shop.shop
        }
      }).then(res => res.json())
        .then(
          (result) => {
  
            console.log('stores were',result)

            if(isMountedRef.current){
  
              setIsStoreLoading(false)
  
              if(result.connected_stores){
  
                set_stores(result.connected_stores)
  
            }else{
  
              props.handle_snack('error','Error fetching stores!',true)
  
            }
  
            }
    
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            if(isMountedRef.current){
              props.handle_snack('error','Error fetching stores!',true)
              setIsStoreLoading(false)
            }
            console.log(error)
            //set_shipping_error(true)
          }
    )
  
  }

  function get_analytics_details(current_shop,section_name,store_domain) {

    set_analytics_data({})

    setAnalyticsIsLoading(true)

    var temp_store = ''

    var temp_stores = [...all_stores]

    temp_stores = temp_stores.map(obj => obj.store_domain)

    const index = temp_stores.indexOf(store_domain)

    if(index > -1){
      temp_store = all_stores[index].domain
    }

console.log('headers were',{
            
  'store_domain': 'https://'+temp_store,
  'section_name':  section_name
})
  
    fetch('https://gt0425yc42.execute-api.eu-west-2.amazonaws.com/first/analytics'+ current_shop.search,{
        crossDomain:true,
        method: 'GET',
        headers: {
            
          'store_domain': 'https://'+temp_store,
          'section_name':  section_name
          
         /*
        'store_domain': 'https://aus.dev.snagtights.com',
        'section_name':  'New Section',
        'theme_id' : '79665037395',
          */
        }})
        .then(res => res.json())
        .then(
          (result) => {

            console.log('resp was',result)

            // copy by value
            // saving data to aws

            if(isMountedRef.current){
                setAnalyticsIsLoading(false)
            if(result.data){
              set_analytics_data(result.data)
            }else if(result.message){
              props.handle_snack('error',result.message,true)
            }else{
              props.handle_snack('error','Error fetching analytics!',true)
            }
          }

          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            if(isMountedRef.current){
                setAnalyticsIsLoading(false)
            props.handle_snack('error','Error fetching analytics!',true)
            console.log(error)
            }
          }
    )
  
  }




  function get_all_sections(current_shop, store_domain) {

    console.log('called Sections')


    set_sections([])
  
    setIsLoading(true)
  
    fetch('https://gt0425yc42.execute-api.eu-west-2.amazonaws.com/first/sections' + current_shop.search,{
        crossDomain:true,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'store_domain': store_domain
        }
      })
        .then(res => res.json())
        .then(
          (result) => {

            if(isMountedRef.current){
            setIsLoading(false)
            if(result.sections){
                if(result.sections.length > 0){
                    set_sections(result.sections)
                  }else{
                    props.handle_snack('error','No Sections yet',true)
                  }
            }else{
              props.handle_snack('error','Error fetching Sections',true)
            }
          }
            console.log('sections were',result)
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            if(isMountedRef.current){
            props.handle_snack('error','Error fetching Sections',true)
            setIsLoading(false)
            console.log(error)
            }
            //set_shipping_error(true)
          }
    )
  
  }




}


Analytics.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Analytics);


// API Gateway, get all stores with current email

// Get all combined shipping zones

// display shipping zones with store name
