import React ,{ useEffect, useState, useRef } from 'react';
import Paperbase from '../paperbase/Paperbase'





const TopLevel= (props) => {

  const [current_shop,set_current_shop] = React.useState({});


  useEffect(() => {

    console.log('search element issss',window.location.search)
 
    console.log('search obj', searchToObject())
 
    const shop_obj = searchToObject()

    shop_obj.search = window.location.search
 
    console.log('cur_shop',shop_obj)

    console.log('search',window.location)

    set_current_shop(shop_obj)
  
   }, [])

   const searchToObject = () => {
    var pairs = window.location.search.substring(1).split("&"),
      obj = {},
      pair,
      i;
  
    for ( i in pairs ) {
      if ( pairs[i] === "" ) continue;
  
      pair = pairs[i].split("=");
      obj[ decodeURIComponent( pair[0] ) ] = decodeURIComponent( pair[1] );
    }
  
    return obj;
  }



  return (
    <div>
    {current_shop.shop && current_shop.search  ?
      <Paperbase current_shop = {current_shop}></Paperbase>
      : <h2 style={{ position: 'absolute', left: '50%', top: '50%', transform: 'translate(-50%, -50%)'}}>This is a Shopify Embedded App, Please access from your Store!</h2>}
    </div>
  );

}

export default TopLevel;

