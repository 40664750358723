import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import Paperbase from './components/paperbase/Paperbase'
import TopLevel from './components/TopLevel/TopLevel'
import App from './components/App/App';
import * as serviceWorker from './serviceWorker';






ReactDOM.render(
  <BrowserRouter><TopLevel /></BrowserRouter>,
  document.getElementById('root')
);

serviceWorker.unregister();
