import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TablePagination from '@material-ui/core/TablePagination';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import DeleteIcon from '@material-ui/icons/Delete';
import FilterListIcon from '@material-ui/icons/FilterList';

import Button from '@material-ui/core/Button';

import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import CssBaseline from '@material-ui/core/CssBaseline';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Link from '@material-ui/core/Link';


const useStyles = makeStyles((theme) => ({
  icon: {
    marginRight: theme.spacing(2),
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  cardGrid: {
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(4),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
  root: {
    width: '100%',
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
}));



//style={{'marginBottom':'40px'}}
//const cards = [1, 2, 3, 4, 5, 6, 7, 8, 9];

export default function Templates(props) {

  const classes = useStyles();

  const handleOpen = (event, t_bd,t_st) => {
    props.set_template_html({'bd':t_bd,'st':t_st})
    props.setOpen({'open_state':true, 'type':'template'});
  };

  const handleSelect = (event, index) => {
    props.select_template(index)
  };
  //borderTop :'1px solid LightGrey'
  //'border':'2px solid grey'
  return (
    <React.Fragment>

      <main>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {props.rows.map((card,index) => (
              <Grid item key={card.template_heading+index.toString()} xs={12} sm={6} md={4}>
                
                <Card variant="elevation" elevation={6} className={classes.card}>
                 { /*<CardMedia
                    className={classes.cardMedia}
                    image={card.url}
                    title="Image title"
                    <div style={{width:'100%',height:'50%',overflow: 'hidden', border:'1px solid black'}} dangerouslySetInnerHTML={ { __html: "<div style = 'height:100%;width:100%; position: relative; top: 50%; left: 50%;transform: translateX(-50%) translateY(-50%);'>" +card.template_body +"</div>"} }></div>   

                 />*/}
               <Paper square = {true} variant='elevation' elevation={2} style={{borderBottomLeftRadius:'120px 40px',borderBottomRightRadius:'120px 40px',overflow: 'hidden',backgroundColor: '#f8f7fc'}}><iframe  style={{width:'100%',height:'200px',overflow: 'hidden', border: '1px solid #f8f7fc','borderRadius': '10px 10px 10px 10px', 'WebkitTransform' : 'scale(0.75,0.6)','MozTransformScale(0.75,0.6)':'','objectFit': 'fill'}} srcDoc={"<html><head><style type='text/css'>"+ card.style_sheet + "</style></head><body>"+"<div style='object-fit: fill'>"+card.template_body+"</div>"+"</body>"} ></iframe></Paper>

                  <CardContent className={classes.cardContent}>
                    <Typography style = {{fontWeight: 'bold'}}variant="h6" component="h2">
                      {card.template_heading}
                    </Typography>
                    <Typography variant = "body2">
                    {card.template_description}
                    </Typography>
                  </CardContent>
                  <CardActions style={{marginTop: '6px'}}>
                    <Button size="small" color="primary" onClick={(event) => handleOpen(event, card.template_body,card.style_sheet)}>
                      View
                    </Button>
                    <Button size="small" color="primary" onClick={(event) => handleSelect(event, index)}>
                      Select
                    </Button>
                  </CardActions>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
    </React.Fragment>
  );
}