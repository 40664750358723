import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import {  Button } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  modal: {
    marginLeft : '15%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  //600px
  paper: {
    position: 'absolute',
    minWidth:'600px',
    maxWidth: '90%',
    maxHeight: 'calc(100vh)',
    overflowY: 'auto',
    backgroundColor: theme.palette.background.paper,
    boxShadow: theme.shadows[5],
    padding: theme.spacing(4, 4, 4,4),
  },
}));


const PreviewModal = (props) => {

  const classes = useStyles();

  const body = (
    <div className={classes.paper}>

  {props.open.type == 'section' ? <div> <style>  {props.section_view_data.st} </style> <div dangerouslySetInnerHTML={ { __html: props.section_view_data.dt } }></div></div>: props.open.type == 'template' ? <div> <style>  {props.template_html.st} </style> <div dangerouslySetInnerHTML={ { __html: props.template_html.bd } }></div> </div>: <div> <style>  {props.style_sheet} </style> <div dangerouslySetInnerHTML={ { __html: props.ck_editor_data } }></div></div>  }  

      {/*
      <h1 id="simple-modal-title" style={{textAlign : 'center'}}>{props.model_heading}</h1>
      <p id="simple-modal-description" style={{textAlign : 'center'}}>
        {props.model_text}
      </p>
      {props.selected_locations.map((location, index) => {
        return <div key = {location.id}><Button onClick = {()=>{console.log(props.redirect_to);window.open('https://' + location.store_domain, '_blank')}} key = {location.id} variant= 'contained' style={{marginTop:'10px', width: '100%', color: 'white',backgroundColor:location.color}}>{location.country}</Button><br></br></div> 
      })}
    */}
    </div>
  );

  return (
    <div>
      <Modal
        className={classes.modal}
        open={props.open.open_state}
        onClose={props.handleClose}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        {body}
      </Modal>
    </div>
  );
}

export default PreviewModal;


/*

 {props.selected_locations.map((name, index) => {
        return  <Button key = {name.toString()}>name</Button>
      })}

*/




//{props.open.type == 'section' ?  <div> <style jsx>  {` .borderClass{ border: 1px solid blue; } `} </style><div dangerouslySetInnerHTML={ { __html:  props.section_view_data } } ></div></div> : props.open.type == 'template' ? <div dangerouslySetInnerHTML={ { __html: props.template_html } }></div> : <div dangerouslySetInnerHTML={ { __html: props.ck_editor_data } }></div> }  
//<p class="borderClass">wwrw</p>
//.borderClass{ border: 1px solid blue; }