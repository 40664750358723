import React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { makeStyles } from '@material-ui/core/styles';

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(2),
    },
  },
}));

export default function CustomSnackbar(props) {

  const classes = useStyles();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    props.handleBar(props.open.severity, props.open.snack_text,false);
  };


  return (
    <div className={classes.root}>
      <Snackbar style = {{marginLeft : '7%'}} open={props.open.is_open} autoHideDuration={6000} onClose={handleClose}>
        <Alert onClose={handleClose} severity={props.open.severity}>
          {props.open.snack_text}
        </Alert>
      </Snackbar>
      {/*
    <Alert severity="success">text</Alert>
      <Alert severity="error">This is an error message!</Alert>
      <Alert severity="warning">This is a warning message!</Alert>
      <Alert severity="info">This is an information message!</Alert>
      */
     } 
    </div>
  );
}
