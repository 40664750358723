import React, { useEffect, useState } from "react";
import { csv } from "d3-fetch";
import { scaleLinear } from "d3-scale";
import {
  ComposableMap,
  Geographies,
  Geography,
  Sphere,
  Graticule,
  ZoomableGroup
} from "react-simple-maps";

const geoUrl =
  "https://raw.githubusercontent.com/zcreativelabs/react-simple-maps/master/topojson-maps/world-110m.json";

const colorScale = scaleLinear()
  .domain([0, 1])
  .range(["#33afea", "#009ce5"]);

const MapChart = (props) => {


  const [data, setData] = useState([]);

  useEffect(() => {
    
    /*
    csv(`/vulnerability.csv`).then(data => {
        console.log('data was',data)
      
    });
    */

    setData(props.country_level_analytics);
    
  }, [props.country_level_analytics]);

  return (
    <div style={{width:'72%',margin: 'auto'}}>
    <ComposableMap data-tip=""
      projectionConfig={{
        rotate: [-10, 0, 0],
        scale: 147
      }}
    >

      <Sphere stroke="#E4E5E6" strokeWidth={0.5} />
      <Graticule stroke="#E4E5E6" strokeWidth={0.5} />
      {data.length > 0 && (
        <Geographies geography={geoUrl}>
          {({ geographies }) =>
            geographies.map(geo => {
              const d = data.find(s => s.country === geo.properties.ISO_A2);
              //console.log('d was',d)
              //console.log('key was',geo)
              return (
                <Geography
                  onMouseEnter={() => {
                  props.setTooltipContent((d &&d.country && d.total_instances) ? d.country + ' ' + d.total_instances : "");
                  }}
                  onMouseLeave={() => {
                  props.setTooltipContent("");
                  }}
                  style={{
                    hover: {
                      //ill: "#F53",
                      outline: "#009ce5"
                    }
                  }}
                  key={geo.rsmKey}
                  geography={geo}
                  fill={d ? colorScale(d['instance_ratio']) : "#F5F4F6"}
                />
              );
            })
          }
        </Geographies>
      )}
    </ComposableMap>
    </div>
  );
};

export default MapChart;

//<ZoomableGroup zoom={1}>