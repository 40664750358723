import React, {useEffect, useState,useRef} from 'react';
import PropTypes from 'prop-types';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Tooltip from '@material-ui/core/Tooltip';
import IconButton from '@material-ui/core/IconButton';
import { withStyles, lighten, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import RefreshIcon from '@material-ui/icons/Refresh';
import EnhancedTable from '../StoreShippingZones/StoreShippingZones'
import Themes from '../Themes/Themes'
import ConfigureForm from '../ConfigureForm/ConfigureForm'
import CircularProgress from '@material-ui/core/CircularProgress'
import PreviewModal from '../PreviewModal/PreviewModal';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Checkbox from '@material-ui/core/Checkbox';
import Backdrop from '@material-ui/core/Backdrop';

import DeleteIcon from '@material-ui/icons/Delete';



const styles = (theme) => ({
  paper: {
    maxWidth: 936,
    margin: 'auto',
    overflow: 'hidden',
  },
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  searchInput: {
    fontSize: theme.typography.fontSize,
  },
  title: {
    flexGrow: 1,
    alignSelf: 'flex-end',
  },
  table: {
    minWidth: 750,
  },
  block: {
    display: 'block',
  },
  contentWrapper: {
    margin: '16px',
  },
  pBar:{
    marginTop: '100px',
    marginBottom: '100px',
    marginLeft: '48%',
    position:'relative',
  },
});

function Stores(props) {

  const isMountedRef = useRef(null);

  const { classes } = props;

  const [isLoading, setIsLoading] = React.useState(false)

  const [stores, set_stores] = React.useState([])

  const [add_value, set_add_value] = React.useState('')

  const [selected_stores, set_selected_stores] = React.useState([])


  const reset_state = () =>{
    setIsLoading(false)
    set_stores([])
  }


  const refresh_clicked = (event) => {
      get_all_stores(props.current_shop)
  };

  const delete_clicked = (event) => {

    const selected_domain = selected_stores[0]

    var temp_store = {};

    var st;
    for (st in stores) {
      if(stores[st].store_domain == selected_domain){
        temp_store = stores[st]
        break
      }
    }

    delete_store(temp_store, st)

    

};


  const isSelected = (name) => selected_stores.indexOf(name) !== -1;


  const handleClick = (event, name) => {


    
    const selectedIndex = selected_stores.indexOf(name);

    console.log(selectedIndex)

    console.log(name)
    
    let newSelected = [];


    if (selectedIndex === -1) {
      newSelected = [name]

    } else if (selectedIndex === 0) {
       newSelected = []
    } 

    set_selected_stores(newSelected)

  };

  useEffect(()=>{
    isMountedRef.current = true
    get_all_stores(props.current_shop)
    return () => isMountedRef.current = false
  },[])

  return (
    <div>   
    <Paper className={classes.paper}>
      <AppBar style={{borderBottom: '1px solid rgba(0, 0, 0, 0.12)'}} position="static" color="default" elevation={0}>
        <Toolbar>
        <Grid container spacing={2} alignItems="center">
       
            <Grid item xs> 
              <TextField
                fullWidth
                placeholder= {""}
                disabled =  {true}
                InputProps={{
                  disableUnderline: true,
                  className: classes.searchInput,
                }}
              />
            </Grid> 
            <Grid item>
              
            {selected_stores.length > 0 ? <Tooltip title="Disconnect store">
             <IconButton aria-label="Disconnect store" onClick = {delete_clicked.bind(this)}>
            <DeleteIcon />
            </IconButton>
              </Tooltip> : null }
              <Tooltip title="Reload">
                <IconButton onClick = {refresh_clicked.bind(this)}>
                  <RefreshIcon className={classes.block} color="inherit" />
                </IconButton>
              </Tooltip>
            </Grid>
          </Grid>
        </Toolbar>
      </AppBar>


      <div className={classes.contentWrapper}>
      {
      isLoading == true ? <CircularProgress className = {classes.pBar} /> : <div>  <Table className={classes.table} aria-label="simple table">
        <TableHead>
          <TableRow>
          <TableCell padding="checkbox"></TableCell>
            <TableCell>Store Domain</TableCell>
            {/*<TableCell align="right">Store Domain</TableCell>*/}
            <TableCell align="right">Store Name</TableCell>
            <TableCell align="right">Email</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {stores.map((row) => {
            const isItemSelected = isSelected(row.store_domain);
            return(
            <TableRow key={row.name} hover aria-checked={isItemSelected}
            tabIndex={-1}
            key={row.store_domain}
            selected={isItemSelected} 
            onClick={(event) => handleClick(event, row.store_domain)}>
              <TableCell padding="checkbox">
            <Checkbox
              checked={isItemSelected}
            />
          </TableCell>
              <TableCell component="th" scope="row">
                {row.store_domain}
              </TableCell>
              {/*<TableCell align="right">{row.store_domain}</TableCell>*/}
              <TableCell align="right">{row.name}</TableCell>
              <TableCell align="right">{row.email}</TableCell>
            </TableRow>
            );
            })}
        </TableBody>
      </Table>

      <div style={{"display":"flex",marginTop:'12px', width:'98%', marginLeft:'1%',border:'1px solid #90caf9', borderRadius:'8px',paddingLeft:'2.4%'}}> <TextField value={add_value} onChange={(event)=> set_add_value(event.target.value)} style={{marginTop:'3px'}} fullWidth placeholder= {"Enter store domain you would like to connect"} InputProps={{ disableUnderline: true, className: classes.searchInput}}/> <Button style = {{marginLeft: '1%', width:'120px'}}  color="primary" onClick={event=> handle_add_store()}>Add store</Button> </div> </div> }

      </div>
    
    </Paper>
    </div>
  );


  function get_all_stores(current_shop) {
    set_stores([])
     set_selected_stores([])
    setIsLoading(true)
  
    fetch('https://gt0425yc42.execute-api.eu-west-2.amazonaws.com/first/connected-stores'+ current_shop.search,{
        crossDomain:true,
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'store_domain': current_shop.shop
        }
      })
        .then(res => res.json())
        .then(
          (result) => {

            console.log(result)
            if(isMountedRef.current){

              setIsLoading(false)

              if(result.connected_stores){

                set_stores(result.connected_stores)

            }else{

              props.handle_snack('error','Error fetching stores!',true)
                // error
            }

            }

          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            if(isMountedRef.current){
              props.handle_snack('error','Error fetching stores!',true)
            setIsLoading(false)
            }
            console.log(error)
            //set_shipping_error(true)
          }
    )
  
  }

  function delete_store(del_st , index) {


    const current_shop = props.current_shop

    if(del_st.store_domain == current_shop.shop){

      props.handle_snack('error',"This is the current store!",true)

      return

    }


    console.log('del st',del_st)

    var del_st_cn_store;

    var type = 'own'

    for (del_st_cn_store in del_st.connected_stores){

      if(del_st.connected_stores[del_st_cn_store] == current_shop.shop){
        type = 'not_own'
      }

    }

    console.log(JSON.stringify({'current_store': current_shop.shop, 'to_store': del_st.store_domain,'type':type}))


  
    setIsLoading(true)
  
    fetch('https://gt0425yc42.execute-api.eu-west-2.amazonaws.com/first/connected-stores'+ current_shop.search,{
        crossDomain:true,
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
          'store_domain': current_shop.shop
        },
        body: JSON.stringify({'current_store': current_shop.shop, 'to_store': del_st.store_domain,'type':type})})
        .then(res => res.json())
        .then(
          (result) => {

            console.log(result)

            if(isMountedRef.current){


              if(result.message) {

                set_selected_stores([])

                const temp_array = [...stores]

                temp_array.splice(index, 1)

                set_stores(temp_array)

                setIsLoading(false)

                props.handle_snack('success','Store successfully disconnected!',true)

            }else if(result.errorMessage){
              setIsLoading(false)
              props.handle_snack('error', result.errorMessage ,true)
                // error
            }else{
              setIsLoading(false)
              props.handle_snack('error', 'Error disconnecting store' ,true)

            }

            }

          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            if(isMountedRef.current){
              props.handle_snack('error','Error fetching stores!',true)
            setIsLoading(false)
            }
            console.log(error)
            //set_shipping_error(true)
          }
    )
  
 

}

  function handle_add_store() {


    if(add_value.trim().length > 0){

    const to_store = add_value

    const current_shop = props.current_shop
  
    setIsLoading(true)
  
    fetch('https://gt0425yc42.execute-api.eu-west-2.amazonaws.com/first/connected-stores'+ current_shop.search,{
        crossDomain:true,
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
          'store_domain': current_shop.shop
        },
        body: JSON.stringify({'current_store': current_shop.shop, 'to_store': to_store})})
        .then(res => res.json())
        .then(
          (result) => {

            console.log(result)
            if(isMountedRef.current){

              setIsLoading(false)

              if(result.message){
                set_add_value('')
                props.handle_snack('success','Store connected!',true)
                get_all_stores(current_shop)

            }else if(result.errorMessage){

              props.handle_snack('error',result.errorMessage,true)
                
            }else{
              props.handle_snack('error', 'Error connecting store',true)
            }

            }

          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            if(isMountedRef.current){
              props.handle_snack('error','Error fetching stores!',true)
            setIsLoading(false)
            }
            console.log(error)
            //set_shipping_error(true)
          }
    )
  
  }else{
    props.handle_snack('error','Please enter a valid domain!',true)
  }
}

}

Stores.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Stores);


// API Gateway, get all stores with current email

// Get all combined shipping zones

// display shipping zones with store name
